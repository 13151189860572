:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --amber: #ffc107;
  --blue: #2196f3;
  --blue-grey: #607d8b;
  --brown: #795548;
  --cyan: #00bcd4;
  --deep-orange: #ff5722;
  --deep-purple: #673ab7;
  --green: #4caf50;
  --grey: #9e9e9e;
  --indigo: #3f51b5;
  --light-blue: #03a9f4;
  --light-green: #8bc34a;
  --lime: #cddc39;
  --orange: #ff9800;
  --pink: #e91e63;
  --purple: #9c27b0;
  --red: #f44336;
  --teal: #009688;
  --yellow: #ffeb3b;
  --primary: #ffe600;
  --primary-dark: #c7b400;
  --primary-light: #ffff55;
  --secondary: #2196f3;
  --secondary-dark: #0069c0;
  --secondary-light: #6ec6ff;
  --danger: #f44336;
  --danger-dark: #d32f2f;
  --danger-light: #ffcdd2;
  --info: #2196f3;
  --info-dark: #1976d2;
  --info-light: #bbdefb;
  --success: #4caf50;
  --success-dark: #388e3c;
  --success-light: #c8e6c9;
  --warning: #ff9800;
  --warning-dark: #f57c00;
  --warning-light: #ffe0b2;
  --dark: #424242;
  --dark-dark: #212121;
  --dark-light: #757575;
  --light: #f5f5f5;
  --light-dark: #e0e0e0;
  --light-light: #fafafa;
  --font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-serif: "Roboto Slab", Georgia, "Times New Roman", Times, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  text-align: left;
  text-align: start;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.428572;
  margin: 0;
}

[dir='rtl'] body {
  text-align: right;
  text-align: start;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

code,
kbd,
pre,
samp {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'], input[type='datetime-local'], input[type='month'], input[type='time'] {
  -webkit-appearance: listbox;
}

label {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.38);
  display: inline-block;
}

legend {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.333334;
  color: inherit;
  display: block;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

output {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

select[multiple], select[size] {
  overflow: auto;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none !important;
}

img {
  border-style: none;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

summary {
  cursor: pointer;
  display: list-item;
}

a {
  background-color: transparent;
  color: #2196f3;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

a:active, a:focus, a:hover {
  color: #2196f3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):active, a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

template {
  display: none;
}

caption {
  text-align: left;
  text-align: start;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  caption-side: bottom;
  color: rgba(0, 0, 0, 0.38);
  min-height: 3.5rem;
  padding: 1.214285rem 1.5rem;
}

[dir='rtl'] caption {
  text-align: right;
  text-align: start;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
  text-align: start;
}

[dir='rtl'] th {
  text-align: right;
  text-align: start;
}

abbr[data-original-title], abbr[title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: bolder;
}

blockquote {
  margin: 0 0 1rem;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

dfn {
  font-style: italic;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

mark {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  max-width: none;
  width: auto;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
}

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
}

@media (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.blockquote {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
  border-left: 0.3125rem solid #ffe600;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.blockquote-footer {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  margin-top: 0.25rem;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}

mark,
.mark {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.2em;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.typography-display-4 {
  font-size: 7rem;
  font-weight: 300;
  letter-spacing: -0.04em;
  line-height: 1;
}

.typography-display-3 {
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.035715;
}

.typography-display-2 {
  font-size: 2.8125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.066667;
}

.typography-display-1 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.176471;
}

.typography-headline {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.333334;
}

.typography-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
}

.typography-subheading {
  font-size: 1.0rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.5;
}

.typography-body-2 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.428572;
}

.typography-body-1 {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.428572;
}

.typography-caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: inherit;
  font-family: inherit;
  margin-bottom: 0.5rem;
}

h1,
.h1 {
  font-size: 2.8125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.066667;
}

h2,
.h2 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.176471;
}

h3,
.h3 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.333334;
}

h4,
.h4 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
}

h5,
.h5 {
  font-size: 1.0rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.5;
}

h6,
.h6 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.428572;
}

.display-1 {
  font-size: 7rem;
  font-weight: 300;
  letter-spacing: -0.04em;
  line-height: 1;
}

.display-2 {
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.035715;
}

.display-3 {
  font-size: 2.8125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.066667;
}

.display-4 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.176471;
}

.lead {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.alert {
  border-radius: 4px;
  border: 0;
  display: block;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  position: relative;
}

.alert-primary {
  background-color: #ffff55;
  color: rgba(0, 0, 0, 0.87);
}

.alert-secondary {
  background-color: #6ec6ff;
  color: rgba(0, 0, 0, 0.87);
}

.alert-danger {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.alert-info {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.alert-success {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.alert-warning {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.alert-dark {
  background-color: #757575;
  color: white;
}

.alert-light {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.alert-dismissible {
  padding-right: 3.5rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: 0.875rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: inherit;
  font-weight: 500;
}

.alert-link:active, .alert-link:focus, .alert-link:hover {
  color: inherit;
}

.badge {
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  padding-right: 0.5em;
  padding-left: 0.5em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.badge:empty {
  display: none;
}

.btn .badge {
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.badge-primary {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.badge-primary[href]:active, .badge-primary[href]:focus, .badge-primary[href]:hover {
  background-color: #c7b400;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.badge-secondary {
  background-color: #2196f3;
  color: white;
}

.badge-secondary[href]:active, .badge-secondary[href]:focus, .badge-secondary[href]:hover {
  background-color: #0069c0;
  color: white;
  text-decoration: none;
}

.badge-danger {
  background-color: #f44336;
  color: white;
}

.badge-danger[href]:active, .badge-danger[href]:focus, .badge-danger[href]:hover {
  background-color: #d32f2f;
  color: white;
  text-decoration: none;
}

.badge-info {
  background-color: #2196f3;
  color: white;
}

.badge-info[href]:active, .badge-info[href]:focus, .badge-info[href]:hover {
  background-color: #1976d2;
  color: white;
  text-decoration: none;
}

.badge-success {
  background-color: #4caf50;
  color: white;
}

.badge-success[href]:active, .badge-success[href]:focus, .badge-success[href]:hover {
  background-color: #388e3c;
  color: white;
  text-decoration: none;
}

.badge-warning {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.badge-warning[href]:active, .badge-warning[href]:focus, .badge-warning[href]:hover {
  background-color: #f57c00;
  color: white;
  text-decoration: none;
}

.badge-dark {
  background-color: #424242;
  color: white;
}

.badge-dark[href]:active, .badge-dark[href]:focus, .badge-dark[href]:hover {
  background-color: #212121;
  color: white;
  text-decoration: none;
}

.badge-light {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.badge-light[href]:active, .badge-light[href]:focus, .badge-light[href]:hover {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.badge-pill {
  border-radius: 1em;
}

.breadcrumb {
  border-radius: 4px;
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  min-height: 3.5rem;
  padding: 0.625rem 1rem;
}

.breadcrumb-item {
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
}

@media (min-width: 576px) {
  .breadcrumb-item {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .breadcrumb-item {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .breadcrumb-item {
    transition: none;
  }
}

.breadcrumb-item:active, .breadcrumb-item:focus, .breadcrumb-item:hover {
  color: rgba(0, 0, 0, 0.87);
}

.breadcrumb-item.active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bolder;
}

.breadcrumb-item a {
  color: inherit;
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item {
  margin-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  color: rgba(0, 0, 0, 0.54);
  content: "chevron_right";
  display: inline-block;
  margin-right: 0.5rem;
}

.carousel {
  position: relative;
}

.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev {
  opacity: 1;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-item {
  transition-duration: 0.375s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  backface-visibility: hidden;
  display: none;
  perspective: 1000px;
  position: relative;
  width: 100%;
}

@media (min-width: 576px) {
  .carousel-item {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .carousel-item {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex;
}

.carousel-item-left.active,
.carousel-item-prev {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-left.active,
  .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-item-left.carousel-item-next,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-left.carousel-item-next,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.carousel-item-right.active {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .carousel-item-right.active {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-fade .carousel-item {
  transition-duration: 0.375s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

@media (min-width: 576px) {
  .carousel-fade .carousel-item {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .carousel-fade .carousel-item {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .carousel-item {
    transition: none;
  }
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-prev.active {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-left.active,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item-prev.active {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-right.active {
  opacity: 0;
}

.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.8125rem;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  margin-top: -1.25rem;
  opacity: 0;
  position: absolute;
  top: 50%;
  user-select: none;
  width: 2.5rem;
}

.carousel-control-next:active, .carousel-control-next:focus, .carousel-control-next:hover,
.carousel-control-prev:active,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  background-color: rgba(204, 204, 204, 0.25);
  color: white;
  text-decoration: none;
}

.carousel-control-next:active,
.carousel-control-prev:active {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 1;
  outline: 0;
}

.carousel-control-next {
  right: 1.25rem;
}

.carousel-control-prev {
  left: 1.25rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.carousel-control-next-icon::before {
  content: "chevron_right";
}

.carousel-control-prev-icon::before {
  content: "chevron_left";
}

.carousel-caption {
  color: white;
  position: absolute;
  right: 25%;
  bottom: 1.5rem;
  left: 25%;
  text-align: center;
  z-index: 1;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: absolute;
  right: 5rem;
  bottom: 0.5rem;
  left: 5rem;
  z-index: 1;
}

.carousel-indicators li {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  cursor: pointer;
  flex: 0 0 auto;
  height: 0.5rem;
  margin-right: 1px;
  margin-left: 1px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 0.5rem;
}

.carousel-indicators .active {
  background-color: rgba(204, 204, 204, 0.25);
}

.close {
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: 0;
  color: rgba(0, 0, 0, 0.38);
  float: right;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  padding: 0;
}

@media (min-width: 576px) {
  .close {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .close {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .close {
    transition: none;
  }
}

.close:active, .close:focus, .close:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.close:focus {
  outline: 0;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

code {
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #bd4147;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}

kbd kbd {
  font-size: 100%;
  font-weight: bolder;
  padding: 0;
}

pre {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 87.5%;
}

pre code {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  padding: 0;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.form-check {
  display: block;
  margin-bottom: 0.5rem;
  padding-left: 1.25rem;
  position: relative;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.form-check-input {
  margin-top: 0.25rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label {
  color: rgba(0, 0, 0, 0.38);
}

.form-check-label {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.form-inline .custom-file,
.form-inline .custom-select {
  width: auto;
}

.form-inline .form-check {
  margin-bottom: 0;
  width: auto;
}

.form-inline .form-control,
.form-inline .form-control-file {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.form-inline .form-control-plaintext {
  display: inline-block;
}

.form-inline .form-group {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.form-inline .input-group {
  width: auto;
}

.col-form-label {
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.714286;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.col-form-label-lg {
  font-size: 1.859375rem;
  line-height: 1.344538;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.col-form-label-sm {
  font-size: 0.710938rem;
  line-height: 1.758242;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.form-text {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  margin-top: 0.5rem;
}

.custom-select-lg + .form-text,
.floating-label-lg + .form-text,
.input-group-lg > .floating-label + .form-text,
.floating-label-lg .custom-select + .form-text,
.input-group-lg > .floating-label .custom-select + .form-text,
.floating-label-lg .form-control + .form-text,
.input-group-lg > .floating-label .form-control + .form-text,
.floating-label-lg .form-control-file + .form-text,
.input-group-lg > .floating-label .form-control-file + .form-text,
.form-control-lg + .form-text,
.textfield-box-lg + .form-text,
.input-group-lg > .textfield-box + .form-text,
.textfield-box-lg .custom-select + .form-text,
.input-group-lg > .textfield-box .custom-select + .form-text,
.textfield-box-lg .form-control + .form-text,
.input-group-lg > .textfield-box .form-control + .form-text,
.textfield-box-lg .form-control-file + .form-text,
.input-group-lg > .textfield-box .form-control-file + .form-text {
  margin-top: 0.75rem;
}

.custom-select-sm + .form-text,
.floating-label-sm + .form-text,
.input-group-sm > .floating-label + .form-text,
.floating-label-sm .custom-select + .form-text,
.input-group-sm > .floating-label .custom-select + .form-text,
.floating-label-sm .form-control + .form-text,
.input-group-sm > .floating-label .form-control + .form-text,
.floating-label-sm .form-control-file + .form-text,
.input-group-sm > .floating-label .form-control-file + .form-text,
.form-control-sm + .form-text,
.textfield-box-sm + .form-text,
.input-group-sm > .textfield-box + .form-text,
.textfield-box-sm .custom-select + .form-text,
.input-group-sm > .textfield-box .custom-select + .form-text,
.textfield-box-sm .form-control + .form-text,
.input-group-sm > .textfield-box .form-control + .form-text,
.textfield-box-sm .form-control-file + .form-text,
.input-group-sm > .textfield-box .form-control-file + .form-text {
  margin-top: 0.25rem;
}

.form-control-plaintext {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0 calc(0.375rem - 1px);
  width: 100%;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.figure {
  display: inline-block;
}

.figure-caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.38);
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  height: auto;
  max-width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.jumbotron {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  padding: 3rem 2rem;
}

.jumbotron-fluid {
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:active, .nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-pills .nav-link {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: background-color, color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.7;
}

@media (min-width: 576px) {
  .nav-pills .nav-link {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .nav-pills .nav-link {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-pills .nav-link {
    transition: none;
  }
}

.nav-pills .nav-link:active, .nav-pills .nav-link:focus, .nav-pills .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.nav-pills .nav-link.active {
  color: #2196f3;
  opacity: 1;
}

.nav-pills .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.nav-pills .nav-link:active {
  opacity: 1;
}

.nav-pills .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.pagination {
  background-color: #f5f5f5;
  display: flex;
  list-style: none;
  padding: 0.625rem 0.5rem;
}

.page-link {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  margin-left: 1px;
  padding: 0.6875rem 1rem;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .page-link {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .page-link {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:active, .page-link:focus, .page-link:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.page-link:focus, .page-link:hover {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
}

.page-link.active, .page-link:active {
  background-color: rgba(153, 153, 153, 0.4);
  background-image: none;
}

.page-link:focus {
  outline: 0;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  background-color: rgba(153, 153, 153, 0.4);
}

.page-item.disabled .page-link {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  cursor: auto;
  pointer-events: none;
}

.pagination-lg .page-link {
  font-size: 0.9375rem;
  padding: 0.78125rem 1rem;
}

.pagination-sm .page-link {
  font-size: 0.8125rem;
  padding: 0.59375rem 1rem;
}

.popover {
  text-align: left;
  text-align: start;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.428572;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 0.875rem;
  margin: 1.5rem;
  max-width: 17.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 240;
}

[dir='rtl'] .popover {
  text-align: right;
  text-align: start;
}

.popover-body {
  padding: 1.25rem 1.5rem;
}

.popover-body > :last-child {
  margin-bottom: 0;
}

.popover-header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 1.25rem 1.5rem 0;
}

.popover-header:empty {
  display: none;
}

.popover-header:last-child {
  padding-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .popover {
    margin: 0.875rem;
  }
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  content: '';
  display: block;
}

.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video,
.embed-responsive .embed-responsive-item {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tbody.collapse.show {
  display: table-row-group;
}

tr.collapse.show {
  display: table-row;
}

.collapsing {
  transition-duration: 0.3s;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  height: 0;
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .collapsing {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .collapsing {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.fade {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

@media (min-width: 576px) {
  .fade {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .fade {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade.show {
  opacity: 1;
}

.btn {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  background-image: none;
  border: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  max-width: 100%;
  min-width: 5.5rem;
  padding: 0.6875rem 1rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .btn {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .btn {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:active, .btn:focus, .btn:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.btn:focus, .btn:hover {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
}

.btn.active, .btn:active {
  background-color: rgba(153, 153, 153, 0.4);
  background-image: none;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
}

.btn.disabled, .btn:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
  opacity: 1;
}

.btn:focus {
  outline: 0;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.show > .btn.dropdown-toggle {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  color: rgba(0, 0, 0, 0.87);
}

.btn-primary.active, .btn-primary:active {
  background-color: #c7b400;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-secondary {
  background-color: #2196f3;
  color: white;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  color: white;
}

.btn-secondary.active, .btn-secondary:active {
  background-color: #0069c0;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-danger {
  background-color: #f44336;
  color: white;
}

.btn-danger:active, .btn-danger:focus, .btn-danger:hover {
  color: white;
}

.btn-danger.active, .btn-danger:active {
  background-color: #d32f2f;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-info {
  background-color: #2196f3;
  color: white;
}

.btn-info:active, .btn-info:focus, .btn-info:hover {
  color: white;
}

.btn-info.active, .btn-info:active {
  background-color: #1976d2;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-success {
  background-color: #4caf50;
  color: white;
}

.btn-success:active, .btn-success:focus, .btn-success:hover {
  color: white;
}

.btn-success.active, .btn-success:active {
  background-color: #388e3c;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-warning {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.btn-warning:active, .btn-warning:focus, .btn-warning:hover {
  color: rgba(0, 0, 0, 0.87);
}

.btn-warning.active, .btn-warning:active {
  background-color: #f57c00;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-dark {
  background-color: #424242;
  color: white;
}

.btn-dark:active, .btn-dark:focus, .btn-dark:hover {
  color: white;
}

.btn-dark.active, .btn-dark:active {
  background-color: #212121;
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.btn-light {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.btn-light:active, .btn-light:focus, .btn-light:hover {
  color: rgba(0, 0, 0, 0.87);
}

.btn-light.active, .btn-light:active {
  background-color: #e0e0e0;
}

.btn-light.disabled, .btn-light:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

[class*='bg-dark'] :not([class*='bg-light']) .btn.disabled, [class*='bg-dark'] :not([class*='bg-light']) .btn:disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
}

.btn-lg,
.btn-group-lg > .btn,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-prepend > .btn {
  font-size: 0.9375rem;
  padding: 0.78125rem 1rem;
}

.btn-sm,
.btn-group-sm > .btn,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-prepend > .btn {
  font-size: 0.8125rem;
  padding: 0.59375rem 1rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.25rem;
}

[type='button'].btn-block,
[type='reset'].btn-block,
[type='submit'].btn-block {
  width: 100%;
}

.btn-link {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  color: #2196f3;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
}

.btn-link:active, .btn-link:focus, .btn-link:hover {
  color: #2196f3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link:hover {
  background-image: none;
}

.btn-link.active, .btn-link:active {
  background-color: transparent;
  box-shadow: none;
}

.btn-link.disabled, .btn-link:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  text-decoration: none;
}

.btn-fluid {
  min-width: 0;
}

[class*='btn-flat'],
[class*='btn-outline'] {
  background-color: transparent;
  box-shadow: none;
}

[class*='btn-flat'].active, [class*='btn-flat']:active,
[class*='btn-outline'].active,
[class*='btn-outline']:active {
  box-shadow: none;
}

[class*='btn-flat'].disabled, [class*='btn-flat']:disabled,
[class*='btn-outline'].disabled,
[class*='btn-outline']:disabled {
  background-color: transparent;
}

.btn-flat-primary, .btn-flat-primary:active, .btn-flat-primary:focus, .btn-flat-primary:hover,
.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  color: #ffe600;
}

.btn-flat-primary.disabled, .btn-flat-primary:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-secondary, .btn-flat-secondary:active, .btn-flat-secondary:focus, .btn-flat-secondary:hover,
.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  color: #2196f3;
}

.btn-flat-secondary.disabled, .btn-flat-secondary:disabled,
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-danger, .btn-flat-danger:active, .btn-flat-danger:focus, .btn-flat-danger:hover,
.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger:focus,
.btn-outline-danger:hover {
  color: #f44336;
}

.btn-flat-danger.disabled, .btn-flat-danger:disabled,
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-info, .btn-flat-info:active, .btn-flat-info:focus, .btn-flat-info:hover,
.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info:focus,
.btn-outline-info:hover {
  color: #2196f3;
}

.btn-flat-info.disabled, .btn-flat-info:disabled,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-success, .btn-flat-success:active, .btn-flat-success:focus, .btn-flat-success:hover,
.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success:focus,
.btn-outline-success:hover {
  color: #4caf50;
}

.btn-flat-success.disabled, .btn-flat-success:disabled,
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-warning, .btn-flat-warning:active, .btn-flat-warning:focus, .btn-flat-warning:hover,
.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning:focus,
.btn-outline-warning:hover {
  color: #ff9800;
}

.btn-flat-warning.disabled, .btn-flat-warning:disabled,
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-dark, .btn-flat-dark:active, .btn-flat-dark:focus, .btn-flat-dark:hover,
.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark:focus,
.btn-outline-dark:hover {
  color: #424242;
}

.btn-flat-dark.disabled, .btn-flat-dark:disabled,
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-light, .btn-flat-light:active, .btn-flat-light:focus, .btn-flat-light:hover,
.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light:focus,
.btn-outline-light:hover {
  color: #f5f5f5;
}

.btn-flat-light.disabled, .btn-flat-light:disabled,
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.btn-flat-light:focus, .btn-flat-light:hover,
.btn-outline-light:focus,
.btn-outline-light:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
}

.btn-flat-light.active, .btn-flat-light:active,
.btn-outline-light.active,
.btn-outline-light:active {
  background-color: rgba(204, 204, 204, 0.25);
}

.btn-float {
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  height: 3.5rem;
  line-height: 3.5rem;
  min-width: 0;
  padding: 0;
  width: 3.5rem;
}

.btn-float.active, .btn-float:active {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-float.disabled, .btn-float:disabled {
  box-shadow: none;
}

.btn-float.btn-sm {
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}

.btn-float-dropdown .dropdown-menu {
  border-radius: 0;
  margin-top: 1rem;
  min-width: 3.5rem;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

.btn-float-dropdown .dropdown-menu::before {
  display: none;
}

.btn-float-dropdown .dropdown-menu .btn-float {
  display: block;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
}

.btn-group,
.btn-group-vertical {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group > .btn-primary.disabled, .btn-group > .btn-primary:disabled,
.btn-group-vertical > .btn-primary.disabled,
.btn-group-vertical > .btn-primary:disabled {
  background-color: #ffff55;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-secondary.disabled, .btn-group > .btn-secondary:disabled,
.btn-group-vertical > .btn-secondary.disabled,
.btn-group-vertical > .btn-secondary:disabled {
  background-color: #6ec6ff;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-danger.disabled, .btn-group > .btn-danger:disabled,
.btn-group-vertical > .btn-danger.disabled,
.btn-group-vertical > .btn-danger:disabled {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-info.disabled, .btn-group > .btn-info:disabled,
.btn-group-vertical > .btn-info.disabled,
.btn-group-vertical > .btn-info:disabled {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-success.disabled, .btn-group > .btn-success:disabled,
.btn-group-vertical > .btn-success.disabled,
.btn-group-vertical > .btn-success:disabled {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-warning.disabled, .btn-group > .btn-warning:disabled,
.btn-group-vertical > .btn-warning.disabled,
.btn-group-vertical > .btn-warning:disabled {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-dark.disabled, .btn-group > .btn-dark:disabled,
.btn-group-vertical > .btn-dark.disabled,
.btn-group-vertical > .btn-dark:disabled {
  background-color: #757575;
  color: white;
}

.btn-group > .btn-light.disabled, .btn-group > .btn-light:disabled,
.btn-group-vertical > .btn-light.disabled,
.btn-group-vertical > .btn-light:disabled {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  transition-duration: 0.3s;
  transition-property: border-color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
  flex: 0 1 auto;
  min-width: 0;
}

@media (min-width: 576px) {
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    transition: none;
  }
}

.btn-group > .btn.active, .btn-group > .btn:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active {
  box-shadow: none;
}

.btn-group > .btn.disabled, .btn-group > .btn:disabled,
.btn-group-vertical > .btn.disabled,
.btn-group-vertical > .btn:disabled {
  opacity: 0.7;
}

.btn-group > .btn[class*='btn-outline'],
.btn-group-vertical > .btn[class*='btn-outline'] {
  opacity: 0.7;
}

.btn-group > .btn[class*='btn-outline'].active, .btn-group > .btn[class*='btn-outline']:active,
.btn-group-vertical > .btn[class*='btn-outline'].active,
.btn-group-vertical > .btn[class*='btn-outline']:active {
  opacity: 1;
}

.btn-group > .btn[class*='btn-outline'].disabled, .btn-group > .btn[class*='btn-outline']:disabled,
.btn-group-vertical > .btn[class*='btn-outline'].disabled,
.btn-group-vertical > .btn[class*='btn-outline']:disabled {
  opacity: 1;
}

.btn-group > .btn-group,
.btn-group > .btn-group-vertical,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group-vertical {
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.btn-group.show > .btn.dropdown-toggle {
  box-shadow: none;
}

.btn-group > .btn,
.btn-group > .btn-group {
  margin-left: -1px;
}

.btn-group > .btn:first-child,
.btn-group > .btn-group:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .dropdown-toggle:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  margin-top: -1px;
  margin-left: 0;
  width: 100%;
}

.btn-group-vertical > .btn:first-child,
.btn-group-vertical > .btn-group:first-child {
  margin-top: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .dropdown-toggle:not(:last-of-type) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-fluid {
  background-color: transparent;
  box-shadow: none;
}

[data-toggle='buttons'] > .btn,
[data-toggle='buttons'] > .btn-group > .btn {
  margin-bottom: 0;
}

[data-toggle='buttons'] > .btn [type='checkbox'],
[data-toggle='buttons'] > .btn [type='radio'],
[data-toggle='buttons'] > .btn-group > .btn [type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn [type='radio'] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.dropdown-toggle.dropdown-toggle-split {
  padding-right: 0.2em;
  padding-left: 0.2em;
}

.dropdown-toggle.dropdown-toggle-split::after {
  margin-right: 0;
  margin-left: 0;
}

.dropleft .dropdown-toggle.dropdown-toggle-split::before {
  margin-right: 0;
  margin-left: 0;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.card {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.card.border-primary {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ffe600;
}

.card.border-primary[href]:active, .card.border-primary[href]:focus, .card.border-primary[href]:hover, .card.border-primary[tabindex]:active, .card.border-primary[tabindex]:focus, .card.border-primary[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ffe600;
}

.card.border-secondary {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #2196f3;
}

.card.border-secondary[href]:active, .card.border-secondary[href]:focus, .card.border-secondary[href]:hover, .card.border-secondary[tabindex]:active, .card.border-secondary[tabindex]:focus, .card.border-secondary[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #2196f3;
}

.card.border-danger {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f44336;
}

.card.border-danger[href]:active, .card.border-danger[href]:focus, .card.border-danger[href]:hover, .card.border-danger[tabindex]:active, .card.border-danger[tabindex]:focus, .card.border-danger[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f44336;
}

.card.border-info {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #2196f3;
}

.card.border-info[href]:active, .card.border-info[href]:focus, .card.border-info[href]:hover, .card.border-info[tabindex]:active, .card.border-info[tabindex]:focus, .card.border-info[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #2196f3;
}

.card.border-success {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #4caf50;
}

.card.border-success[href]:active, .card.border-success[href]:focus, .card.border-success[href]:hover, .card.border-success[tabindex]:active, .card.border-success[tabindex]:focus, .card.border-success[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #4caf50;
}

.card.border-warning {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ff9800;
}

.card.border-warning[href]:active, .card.border-warning[href]:focus, .card.border-warning[href]:hover, .card.border-warning[tabindex]:active, .card.border-warning[tabindex]:focus, .card.border-warning[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ff9800;
}

.card.border-dark {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #424242;
}

.card.border-dark[href]:active, .card.border-dark[href]:focus, .card.border-dark[href]:hover, .card.border-dark[tabindex]:active, .card.border-dark[tabindex]:focus, .card.border-dark[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #424242;
}

.card.border-light {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f5f5f5;
}

.card.border-light[href]:active, .card.border-light[href]:focus, .card.border-light[href]:hover, .card.border-light[tabindex]:active, .card.border-light[tabindex]:focus, .card.border-light[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f5f5f5;
}

.card[href]:active, .card[href]:focus, .card[href]:hover, .card[tabindex]:active, .card[tabindex]:focus, .card[tabindex]:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.card[href]:focus, .card[tabindex]:focus {
  outline: 0;
}

.accordion .card:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-body:first-child,
.accordion .card:not(:first-of-type) .card-footer:first-child,
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card:not(:last-of-type) .card-body:last-child,
.accordion .card:not(:last-of-type) .card-footer:last-child,
.accordion .card:not(:last-of-type) .card-header:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .collapse .card-body:first-child,
.accordion .collapse .card-footer:first-child,
.accordion .collapse .card-header:first-child,
.accordion .collapsing .card-body:first-child,
.accordion .collapsing .card-footer:first-child,
.accordion .collapsing .card-header:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-actions {
  align-items: flex-start;
  display: flex;
  padding: 0.5rem 0rem 0.25rem 0.5rem;
}

.card-actions:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-actions:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-actions .btn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 1 auto;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  min-width: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card-actions .dropdown-toggle::after {
  margin-right: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-body:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-body:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-body > :last-child {
  margin-bottom: 0;
}

.card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 1rem;
}

.card-footer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 0;
}

.card-footer:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-footer > :last-child {
  margin-bottom: 0;
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  padding: 1rem 1rem;
}

.card-header:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-header:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header-pills {
  margin: -1rem -1rem;
  padding: 0.5rem 0rem 0.25rem 0.5rem;
}

.card-header-pills .nav-link {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card-header-tabs {
  margin: -1rem -1rem calc(-1rem - 1px);
}

.card-img {
  border-radius: 4px;
}

.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-img-overlay {
  max-height: 100%;
  padding: 1rem 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.card-columns {
  column-count: 2;
  column-gap: 0.5rem;
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  orphans: 1;
  widows: 1;
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
  }
}

.card-columns .card {
  display: inline-flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
}

.card-deck .card {
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .card-deck .card {
    flex: 1 0 0;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
}

.card-group .card {
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .card-group .card {
    flex: 1 0 0;
  }
  .card-group .card:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child:not(:last-child) .card-actions,
  .card-group .card:first-child:not(:last-child) .card-body,
  .card-group .card:first-child:not(:last-child) .card-footer,
  .card-group .card:first-child:not(:last-child) .card-header,
  .card-group .card:first-child:not(:last-child) .card-img,
  .card-group .card:first-child:not(:last-child) .card-img-bottom,
  .card-group .card:first-child:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child:not(:first-child) .card-actions,
  .card-group .card:last-child:not(:first-child) .card-body,
  .card-group .card:last-child:not(:first-child) .card-footer,
  .card-group .card:last-child:not(:first-child) .card-header,
  .card-group .card:last-child:not(:first-child) .card-img,
  .card-group .card:last-child:not(:first-child) .card-img-bottom,
  .card-group .card:last-child:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) .card-actions,
  .card-group .card:not(:first-child):not(:last-child) .card-body,
  .card-group .card:not(:first-child):not(:last-child) .card-footer,
  .card-group .card:not(:first-child):not(:last-child) .card-header,
  .card-group .card:not(:first-child):not(:last-child) .card-img,
  .card-group .card:not(:first-child):not(:last-child) .card-img-bottom,
  .card-group .card:not(:first-child):not(:last-child) .card-img-top {
    border-radius: 0;
  }
}

.card-link:active, .card-link:focus, .card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.428572;
  margin-top: -1rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.333334;
  margin-bottom: 1rem;
}

.card-title:last-child {
  margin-bottom: 0;
}

.chip {
  align-items: center;
  background-color: #e0e0e0;
  border: 0;
  border-radius: 1rem;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.chip:empty {
  display: none;
}

.chip .close {
  font-size: inherit;
  line-height: inherit;
  margin-right: -0.5rem;
  margin-left: 0.25rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  order: 1;
}

.chip-primary {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.chip-secondary {
  background-color: #2196f3;
  color: white;
}

.chip-danger {
  background-color: #f44336;
  color: white;
}

.chip-info {
  background-color: #2196f3;
  color: white;
}

.chip-success {
  background-color: #4caf50;
  color: white;
}

.chip-warning {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.chip-dark {
  background-color: #424242;
  color: white;
}

.chip-light {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.chip-action {
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .chip-action {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .chip-action {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .chip-action {
    transition: none;
  }
}

.chip-action:active, .chip-action:focus, .chip-action:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.chip-action:active {
  background-color: #bdbdbd;
}

.chip-action:focus {
  outline: 0;
}

.chip-icon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  background-color: #2196f3;
  border-radius: 1rem;
  color: white;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  height: 2rem;
  justify-content: center;
  margin-right: 0.5rem;
  margin-left: -0.75rem;
  order: -1;
  text-align: center;
  width: 2rem;
}

.chip-img {
  border-radius: 1rem;
  flex-shrink: 0;
  height: 2rem;
  margin-right: 0.5rem;
  margin-left: -0.75rem;
  order: -1;
  width: auto;
}

.table {
  background-color: #ffffff;
  border: 0;
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%;
}

.table td,
.table th {
  border-top: 1px solid #e1e1e1;
  line-height: 1.428572;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  vertical-align: top;
}

.table td:first-child,
.table th:first-child {
  padding-left: 1.5rem;
}

.table td:last-child,
.table th:last-child {
  padding-right: 1.5rem;
}

.table tbody {
  color: rgba(0, 0, 0, 0.87);
}

.table tbody td,
.table tbody th {
  font-size: 0.8125rem;
  font-weight: 400;
  height: 3rem;
  padding-top: 0.919643rem;
  padding-bottom: 0.919643rem;
}

.table tfoot {
  color: rgba(0, 0, 0, 0.54);
}

.table tfoot td,
.table tfoot th {
  font-size: 0.75rem;
  font-weight: 400;
  height: 3.5rem;
  padding-top: 1.214285rem;
  padding-bottom: 1.214285rem;
}

.table thead {
  color: rgba(0, 0, 0, 0.54);
}

.table thead td,
.table thead th {
  font-size: 0.75rem;
  font-weight: 500;
  height: 3.5rem;
  padding-top: 1.214285rem;
  padding-bottom: 1.214285rem;
}

.card > .table:first-child,
.card > .table:first-child > :first-child,
.card > .table:first-child > :first-child > tr:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card > .table:first-child > :first-child > tr:first-child td:first-child,
.card > .table:first-child > :first-child > tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

.card > .table:first-child > :first-child > tr:first-child td:last-child,
.card > .table:first-child > :first-child > tr:first-child th:last-child {
  border-top-right-radius: 4px;
}

.card > .table:last-child,
.card > .table:last-child > :last-child,
.card > .table:last-child > :last-child > tr:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card > .table:last-child > :last-child > tr:last-child td:first-child,
.card > .table:last-child > :last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 4px;
}

.card > .table:last-child > :last-child > tr:last-child td:last-child,
.card > .table:last-child > :last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 4px;
}

.table .table {
  border-top: 1px solid #e1e1e1;
}

.table > :first-child > tr:first-child td,
.table > :first-child > tr:first-child th {
  border-top: 0;
}

.table-borderless td,
.table-borderless th,
.table-borderless .table {
  border: 0;
}

.table-bordered {
  border: 1px solid #e1e1e1;
}

.card > .table-bordered {
  border: 0;
}

.table-sm td,
.table-sm th {
  padding-right: 1rem;
  padding-left: 1rem;
}

.table-sm td:first-child,
.table-sm th:first-child {
  padding-left: 1rem;
}

.table-sm td:last-child,
.table-sm th:last-child {
  padding-right: 1rem;
}

.table-sm tbody td,
.table-sm tbody th {
  height: 2.25rem;
  padding-top: 0.544643rem;
  padding-bottom: 0.544643rem;
}

.table-sm tfoot td,
.table-sm tfoot th {
  padding-top: 0.714286rem;
  padding-bottom: 0.714286rem;
}

.table-sm thead td,
.table-sm thead th {
  height: 2.5rem;
  padding-top: 0.714286rem;
  padding-bottom: 0.714286rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.table-hover tbody tr:hover {
  background-color: #eeeeee;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #ffff55;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #6ec6ff;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #2196f3;
  color: white;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f44336;
  color: white;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #2196f3;
  color: white;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #4caf50;
  color: white;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #757575;
  color: white;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #424242;
  color: white;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.table .thead-dark td,
.table .thead-dark th {
  background-color: #424242;
  color: white;
}

.table .thead-light td,
.table .thead-light th {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
}

.table-dark {
  background-color: #424242;
  color: white;
}

.table-dark.table-bordered {
  border-color: #303030;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #303030;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #212121;
}

.table-dark tbody,
.table-dark tfoot,
.table-dark thead {
  color: inherit;
}

.table-dark td,
.table-dark th,
.table-dark .table {
  border-color: #303030;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.modal {
  display: none;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 240;
}

.modal.fade {
  transition-duration: 0.375s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .modal.fade {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .modal.fade {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade {
    transition: none;
  }
}

.modal.fade .modal-dialog {
  transition-duration: 0.375s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0.87);
}

@media (min-width: 576px) {
  .modal.fade .modal-dialog {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .modal.fade .modal-dialog {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: scale(1);
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.38);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 239;
}

.modal-content {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  vertical-align: baseline;
  width: 100%;
}

.modal-dialog {
  margin: 1.5rem auto;
  max-width: 35rem;
  pointer-events: none;
  position: relative;
  width: calc(100% - 1.5rem * 2);
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1.5rem * 2);
}

.modal-lg {
  max-width: 52.5rem;
}

.modal-sm {
  max-width: 17.5rem;
}

.modal-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.5rem;
  position: relative;
}

.modal-body:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-body:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-header + .modal-body {
  padding-top: 0;
}

.modal-body > :last-child {
  margin-bottom: 0;
}

.modal-footer {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.modal-footer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-footer:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-footer .btn {
  background-color: transparent;
  box-shadow: none;
  max-width: calc(50% - 0.5rem);
  min-width: 4rem;
  overflow: hidden;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
}

.modal-footer .btn-primary, .modal-footer .btn-primary:active, .modal-footer .btn-primary:focus, .modal-footer .btn-primary:hover {
  color: #ffe600;
}

.modal-footer .btn-primary.disabled, .modal-footer .btn-primary:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-secondary, .modal-footer .btn-secondary:active, .modal-footer .btn-secondary:focus, .modal-footer .btn-secondary:hover {
  color: #2196f3;
}

.modal-footer .btn-secondary.disabled, .modal-footer .btn-secondary:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-danger, .modal-footer .btn-danger:active, .modal-footer .btn-danger:focus, .modal-footer .btn-danger:hover {
  color: #f44336;
}

.modal-footer .btn-danger.disabled, .modal-footer .btn-danger:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-info, .modal-footer .btn-info:active, .modal-footer .btn-info:focus, .modal-footer .btn-info:hover {
  color: #2196f3;
}

.modal-footer .btn-info.disabled, .modal-footer .btn-info:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-success, .modal-footer .btn-success:active, .modal-footer .btn-success:focus, .modal-footer .btn-success:hover {
  color: #4caf50;
}

.modal-footer .btn-success.disabled, .modal-footer .btn-success:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-warning, .modal-footer .btn-warning:active, .modal-footer .btn-warning:focus, .modal-footer .btn-warning:hover {
  color: #ff9800;
}

.modal-footer .btn-warning.disabled, .modal-footer .btn-warning:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-dark, .modal-footer .btn-dark:active, .modal-footer .btn-dark:focus, .modal-footer .btn-dark:hover {
  color: #424242;
}

.modal-footer .btn-dark.disabled, .modal-footer .btn-dark:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn-light, .modal-footer .btn-light:active, .modal-footer .btn-light:focus, .modal-footer .btn-light:hover {
  color: #f5f5f5;
}

.modal-footer .btn-light.disabled, .modal-footer .btn-light:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer .btn.active, .modal-footer .btn:active {
  background-color: rgba(153, 153, 153, 0.4);
  box-shadow: none;
}

.modal-footer .btn.disabled, .modal-footer .btn:disabled {
  background-color: transparent;
}

.modal-footer > * {
  margin-left: 0.5rem;
}

.modal-footer-stacked {
  align-items: stretch;
  flex-direction: column;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.modal-footer-stacked .btn {
  text-align: right;
  text-align: end;
  border-radius: 0;
  margin-left: 0;
  max-width: none;
  padding: 1.0625rem 1rem;
}

[dir='rtl'] .modal-footer-stacked .btn {
  text-align: left;
  text-align: end;
}

.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
}

.modal-header:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-header:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
  margin: 0;
}

.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -99999px;
  width: 50px;
}

.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item {
  transition-duration: 0.3s;
  transition-property: background-color, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 0.9375rem;
  line-height: 1.428572;
  min-height: 3rem;
  padding: 0.830357rem 1.5rem;
  position: relative;
}

@media (min-width: 576px) {
  .list-group-item {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .list-group-item {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .list-group-item {
    transition: none;
  }
}

.list-group-item:active, .list-group-item:focus, .list-group-item:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.list-group-item.active {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item.disabled, .list-group-item:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card .list-group-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.list-group-item-action {
  color: rgba(0, 0, 0, 0.87);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:active, .list-group-item-action:focus, .list-group-item-action:hover {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.list-group-item-action.disabled, .list-group-item-action:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
}

.list-group-item-action:focus {
  outline: 0;
}

.list-group-item-primary {
  background-color: #ffff55;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-primary.active {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-primary.list-group-item-action:active, .list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-secondary {
  background-color: #6ec6ff;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-secondary.active {
  background-color: #2196f3;
  color: white;
}

.list-group-item-secondary.list-group-item-action:active, .list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  background-color: #2196f3;
  color: white;
}

.list-group-item-danger {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-danger.active {
  background-color: #f44336;
  color: white;
}

.list-group-item-danger.list-group-item-action:active, .list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  background-color: #f44336;
  color: white;
}

.list-group-item-info {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-info.active {
  background-color: #2196f3;
  color: white;
}

.list-group-item-info.list-group-item-action:active, .list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  background-color: #2196f3;
  color: white;
}

.list-group-item-success {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-success.active {
  background-color: #4caf50;
  color: white;
}

.list-group-item-success.list-group-item-action:active, .list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  background-color: #4caf50;
  color: white;
}

.list-group-item-warning {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-warning.active {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-warning.list-group-item-action:active, .list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-dark {
  background-color: #757575;
  color: white;
}

.list-group-item-dark.active {
  background-color: #424242;
  color: white;
}

.list-group-item-dark.list-group-item-action:active, .list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  background-color: #424242;
  color: white;
}

.list-group-item-light {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-light.active {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-light.list-group-item-action:active, .list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.expansion-panel {
  transition-duration: 0.3s;
  transition-property: background-color, color, margin;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
}

@media (min-width: 576px) {
  .expansion-panel {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .expansion-panel {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .expansion-panel {
    transition: none;
  }
}

.expansion-panel.show {
  border-radius: 4px;
}

.expansion-panel.show:not(:first-child) {
  margin-top: 1rem;
}

.card .expansion-panel.show:not(:first-child) {
  margin-top: 0;
}

.expansion-panel.show:not(:last-child) {
  margin-bottom: 1rem;
}

.card .expansion-panel.show:not(:last-child) {
  margin-bottom: 0;
}

.expansion-panel.show + .expansion-panel {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.expansion-panel.show + .expansion-panel.show {
  margin-top: 0;
}

.card .expansion-panel.show + .expansion-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.expansion-panel.show-predecessor {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card .expansion-panel.show-predecessor {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card .expansion-panel {
  padding-right: 0;
  padding-left: 0;
}

.expansion-panel-body {
  padding: 1rem 1.5rem;
}

.card .expansion-panel-body {
  padding-right: 1rem;
  padding-left: 1rem;
}

.expansion-panel-footer {
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem 0.75rem 0rem;
}

.card .expansion-panel-footer {
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.expansion-panel-footer .btn {
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
  min-width: 4rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card .expansion-panel-footer .btn {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.expansion-panel-icon {
  flex-shrink: 0;
  margin-left: 1rem;
}

[data-toggle='collapse'].collapsed .expansion-panel-icon .collapsed-hide {
  display: none;
}

[data-toggle='collapse']:not(.collapsed) .expansion-panel-icon .collapsed-show {
  display: none;
}

.expansion-panel-toggler {
  align-items: center;
  color: inherit;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.830357rem 1.5rem;
  text-align: inherit;
  width: 100%;
}

.expansion-panel-toggler:active, .expansion-panel-toggler:focus, .expansion-panel-toggler:hover {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.expansion-panel-toggler.disabled, .expansion-panel-toggler:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
}

.expansion-panel-toggler:focus {
  outline: 0;
}

.card .expansion-panel-toggler {
  padding-right: 1rem;
  padding-left: 1rem;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-flush .list-group-item {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  box-shadow: none;
}

.list-group-flush .list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-menu {
  border-radius: 4px;
  text-align: left;
  text-align: start;
  background-color: transparent;
  color: inherit;
  display: none;
  float: left;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  list-style: none;
  margin: 0;
  min-width: 7rem;
  padding: 0.5rem 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 80;
}

[dir='rtl'] .dropdown-menu {
  text-align: right;
  text-align: start;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu.show::before,
.dropdown-menu.show > * {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .dropdown-menu.show::before,
  .dropdown-menu.show > * {
    animation-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .dropdown-menu.show::before,
  .dropdown-menu.show > * {
    animation-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropdown-menu.show::before,
  .dropdown-menu.show > * {
    animation: none;
  }
}

.dropdown-menu.show::before {
  animation-name: dropdown-menu-show;
}

.dropdown-menu.show > * {
  animation-name: dropdown-item-show;
}

.dropdown-menu.show > :nth-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropdown-menu.show > :nth-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropdown-menu.show > :nth-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropdown-menu::before {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform-origin: 0 0;
  z-index: -1;
}

.nav:not(.flex-column):not(.flex-column-reverse) .dropdown-menu {
  min-width: 100%;
}

.menu {
  margin-top: -3.125rem;
}

.menu::before {
  transform-origin: 0 2rem;
}

.show > a {
  outline: 0;
}

.dropdown-menu-right:not([x-placement]) {
  right: 0;
  left: auto;
}

.dropdown-menu-right:not([x-placement]).menu::before {
  transform-origin: 100% 2rem;
}

.dropdown-menu-right:not([x-placement])::before {
  transform-origin: 100% 0;
}

.dropleft .dropdown-menu:not([x-placement]) {
  top: 0;
  right: 100%;
  left: auto;
}

.dropleft .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
}

.dropleft .dropdown-menu:not([x-placement])::before {
  transform-origin: 100% 0;
}

.dropright .dropdown-menu:not([x-placement]) {
  top: 0;
  left: 100%;
}

.dropright .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
}

.dropright .dropdown-menu:not([x-placement])::before {
  transform-origin: 0 0;
}

.dropup .dropdown-menu:not([x-placement]) {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
  margin-bottom: -3.125rem;
}

.dropup .dropdown-menu:not([x-placement]).menu::before {
  transform-origin: 0 calc(100% - 2rem);
}

.dropup .dropdown-menu:not([x-placement])::before {
  transform-origin: 0 100%;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-child(1),
.dropup .dropdown-menu:not([x-placement]) > :nth-child(2),
.dropup .dropdown-menu:not([x-placement]) > :nth-child(3) {
  animation-name: dropdown-item-show;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropup .dropdown-menu-right:not([x-placement]).menu::before {
  transform-origin: 100% calc(100% - 2rem);
}

.dropup .dropdown-menu-right:not([x-placement])::before {
  transform-origin: 100% 100%;
}

.dropdown-menu[x-placement='bottom-end']::before,
.dropdown-menu[x-placement='left-start']::before {
  transform-origin: 100% 0;
}

.dropdown-menu[x-placement='top-end'].show > :nth-child(1),
.dropdown-menu[x-placement='top-end'].show > :nth-child(2),
.dropdown-menu[x-placement='top-end'].show > :nth-child(3),
.dropdown-menu[x-placement='top-start'].show > :nth-child(1),
.dropdown-menu[x-placement='top-start'].show > :nth-child(2),
.dropdown-menu[x-placement='top-start'].show > :nth-child(3) {
  animation-name: dropdown-item-show;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(1),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(2),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(3),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropdown-menu[x-placement='top-end']::before {
  transform-origin: 100% 100%;
}

.dropdown-menu[x-placement='top-start']::before {
  transform-origin: 0 100%;
}

.menu[x-placement='bottom-end']::before {
  transform-origin: 100% 2rem;
}

.menu[x-placement='left-start'],
.menu[x-placement='right-start'] {
  margin-top: 0;
}

.menu[x-placement='left-start']::before {
  transform-origin: 100% 0;
}

.menu[x-placement='right-start']::before {
  transform-origin: 0 0;
}

.menu[x-placement='top-end'],
.menu[x-placement='top-start'] {
  margin-top: 0;
  margin-bottom: -3.125rem;
}

.menu[x-placement='top-end']::before {
  transform-origin: 100% calc(100% - 2rem);
}

.menu[x-placement='top-start']::before {
  transform-origin: 0 calc(100% - 2rem);
}

@keyframes dropdown-item-show {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-1 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-3 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-menu-show {
  0% {
    transform: scale(0, 0);
  }
  20% {
    transform: scale(0.333333, 0);
  }
  40% {
    transform: scale(0.666667, 0.25);
  }
  60% {
    transform: scale(1, 0.5);
  }
  80% {
    transform: scale(1, 0.75);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes menu-animation {
  0% {
    margin-top: -3.5rem;
  }
  100% {
    margin-top: 0;
  }
}

.dropdown-menu-sm,
.menu-cascading {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 576px) {
  .dropdown-menu-sm,
  .menu-cascading {
    min-width: 20rem;
  }
}

.menu-cascading {
  margin-top: -2.75rem;
}

.menu-cascading[x-placement='top-end'], .menu-cascading[x-placement='top-start'] {
  margin-top: 0;
  margin-bottom: -2.75rem;
}

.menu-cascading[x-placement='top-end']::before {
  transform-origin: 100% calc(100% - 2rem);
}

.menu-cascading[x-placement='top-start']::before {
  transform-origin: 0 calc(100% - 2rem);
}

.dropdown-divider {
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
}

.dropdown-header {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.75rem 1rem;
  white-space: nowrap;
}

.dropdown-menu-sm .dropdown-header,
.menu-cascading .dropdown-header {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 0.25rem 1.5rem;
}

.dropdown-item {
  transition-duration: 0.3s;
  transition-property: background-color, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background: none;
  border: 0;
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-weight: inherit;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: inherit;
  transform-origin: 0 0;
  white-space: nowrap;
  width: 100%;
}

@media (min-width: 576px) {
  .dropdown-item {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .dropdown-item {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropdown-item {
    transition: none;
  }
}

.dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.dropdown-item.active {
  background-color: #f5f5f5;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}

.dropdown-menu-sm .dropdown-item,
.menu-cascading .dropdown-item {
  padding: 0.25rem 1.5rem;
}

.dropdown-item-text {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-weight: inherit;
  padding: 0.75rem 1rem;
  transform-origin: 0 0;
}

.dropdown-menu-sm .dropdown-item-text,
.menu-cascading .dropdown-item-text {
  padding: 0.25rem 1.5rem;
}

.dropdown-toggle::after {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "expand_more";
  display: inline-block;
  margin-right: -0.2em;
  margin-left: 0.2em;
  vertical-align: top;
}

.dropright .dropdown-toggle::after {
  content: "keyboard_arrow_right";
}

.dropup .dropdown-toggle::after {
  content: "expand_less";
}

.dropdown-toggle:empty::after {
  margin-left: -0.2em;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "keyboard_arrow_left";
  display: inline-block;
  margin-right: 0.2em;
  margin-left: -0.2em;
}

.navdrawer {
  display: none;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 160;
}

.navdrawer-backdrop {
  transition-duration: 0.375s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.38);
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 159;
}

@media (min-width: 576px) {
  .navdrawer-backdrop {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .navdrawer-backdrop {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navdrawer-backdrop {
    transition: none;
  }
}

.navdrawer-backdrop.show {
  opacity: 1;
}

.navdrawer-content {
  transition-duration: 0.195s;
  transition-property: box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  background-color: #ffffff;
  max-width: calc(100% - 3.5rem);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  width: 17.5rem;
}

@media (min-width: 576px) {
  .navdrawer-content {
    transition-duration: 0.2535s;
  }
}

@media (min-width: 992px) {
  .navdrawer-content {
    transition-duration: 0.13s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navdrawer-content {
    transition: none;
  }
}

.navdrawer-right .navdrawer-content {
  right: 0;
  left: auto;
  transform: translate3d(100%, 0, 0);
}

.navdrawer.show .navdrawer-content {
  transition-duration: 0.225s;
  transition-property: box-shadow, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  .navdrawer.show .navdrawer-content {
    transition-duration: 0.2925s;
  }
}

@media (min-width: 992px) {
  .navdrawer.show .navdrawer-content {
    transition-duration: 0.15s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navdrawer.show .navdrawer-content {
    transition: none;
  }
}

.navdrawer-body {
  margin-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navdrawer-divider {
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
}

.navdrawer-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  margin-bottom: 0.5rem;
  padding: 0.625rem 1rem;
}

.navdrawer-subheader {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  font-weight: 500;
  height: 3rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.0625rem 1rem;
}

.navdrawer-divider + .navdrawer-subheader {
  margin-top: -0.5rem;
}

.navdrawer-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0;
}

.navdrawer-header + .navdrawer-nav,
.navdrawer-subheader + .navdrawer-nav {
  margin-top: 0;
}

.navdrawer-nav .nav-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  padding: 1.0625rem 1rem;
}

@media (min-width: 576px) {
  .navdrawer-nav .nav-link {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navdrawer-nav .nav-link {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navdrawer-nav .nav-link {
    transition: none;
  }
}

.navdrawer-nav .nav-link:active, .navdrawer-nav .nav-link:focus, .navdrawer-nav .nav-link:hover {
  background-color: #f5f5f5;
}

.navdrawer-nav .nav-link.active, .navdrawer-nav .nav-link:active {
  color: #2196f3;
}

.navdrawer-nav .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
}

.navdrawer-nav .nav-link:focus {
  outline: 0;
}

.navdrawer-nav .active > .nav-link {
  color: #2196f3;
}

.navdrawer-nav-icon {
  color: rgba(0, 0, 0, 0.54);
  width: 3.5rem;
}

.nav-link:active .navdrawer-nav-icon,
.nav-link.active .navdrawer-nav-icon {
  color: #2196f3;
}

.active > .nav-link .navdrawer-nav-icon {
  color: #2196f3;
}

@media (min-width: 576px) {
  .navdrawer-backdrop-permanent-sm {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-sm {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-sm {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-sm {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-clipped, .navdrawer-permanent-sm.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-sm.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-sm.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-sm .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-sm {
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-persistent-sm.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-sm.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-sm.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-sm.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-sm .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-sm {
    overflow: visible;
    right: auto;
    width: 17.5rem;
  }
  .navdrawer-temporary-sm.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-sm .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navdrawer-backdrop-permanent-md {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-md {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-md {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-md {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-permanent-md.navdrawer-permanent-clipped, .navdrawer-permanent-md.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-md.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-md.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-md .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-md {
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-persistent-md.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-md.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-md.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-md.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-md .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-md {
    overflow: visible;
    right: auto;
    width: 17.5rem;
  }
  .navdrawer-temporary-md.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-md .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .navdrawer-backdrop-permanent-lg {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-lg {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-lg {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-lg {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-clipped, .navdrawer-permanent-lg.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-lg.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-lg.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-lg .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-lg {
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-persistent-lg.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-lg.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-lg.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-lg.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-lg .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-lg {
    overflow: visible;
    right: auto;
    width: 17.5rem;
  }
  .navdrawer-temporary-lg.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-lg .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .navdrawer-backdrop-permanent-xl {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-xl {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-xl {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-xl {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-clipped, .navdrawer-permanent-xl.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-xl.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-xl.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-xl .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-xl {
    right: auto;
    width: 17.5rem;
    z-index: auto;
  }
  .navdrawer-persistent-xl.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-xl.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-xl.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-xl.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-xl .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-xl {
    overflow: visible;
    right: auto;
    width: 17.5rem;
  }
  .navdrawer-temporary-xl.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-xl .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

.navdrawer-backdrop-permanent {
  display: none;
  transition: none;
}

.navdrawer-backdrop-persistent {
  display: none;
  transition: none;
}

.navdrawer-backdrop-temporary {
  display: none;
  transition: none;
}

.navdrawer-permanent {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: block !important;
  right: auto;
  width: 17.5rem;
  z-index: auto;
}

.navdrawer-permanent.navdrawer-permanent-clipped, .navdrawer-permanent.navdrawer-permanent-float {
  top: 3.5rem;
}

.navdrawer-permanent.navdrawer-permanent-float {
  border-right: 0;
}

.navdrawer-permanent.navdrawer-permanent-float.navdrawer-right {
  border-left: 0;
}

.navdrawer-permanent.navdrawer-permanent-float .navdrawer-content {
  background-color: transparent;
}

.navdrawer-permanent.navdrawer-right {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  right: 0;
  left: auto;
}

.navdrawer-permanent.show .navdrawer-content {
  box-shadow: none;
}

.navdrawer-permanent .navdrawer-content {
  max-width: none;
  position: absolute;
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.navdrawer-persistent {
  right: auto;
  width: 17.5rem;
  z-index: auto;
}

.navdrawer-persistent.navdrawer-persistent-clipped {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: -1px;
  top: 3.5rem;
  z-index: 39;
}

.navdrawer-persistent.navdrawer-right {
  right: 0;
  left: auto;
}

.navdrawer-persistent.navdrawer-right .navdrawer-content {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.navdrawer-persistent.show .navdrawer-content {
  box-shadow: none;
}

.navdrawer-persistent .navdrawer-content {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  max-width: none;
  position: absolute;
  width: 100%;
}

.navdrawer-temporary {
  overflow: visible;
  right: auto;
  width: 17.5rem;
}

.navdrawer-temporary.navdrawer-right {
  right: 0;
  left: auto;
}

.navdrawer-temporary .navdrawer-content {
  max-width: none;
  position: absolute;
  width: 100%;
}

.picker {
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 100%, 0);
  transition-delay: 0.675s;
  transition-duration: 0;
  transition-property: transform;
  z-index: 240;
}

@media (min-width: 576px) {
  .picker {
    transition-delay: 0.8775s;
  }
}

@media (min-width: 992px) {
  .picker {
    transition-delay: 0.45s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker {
    transition: none;
  }
}

.picker.picker-opened {
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  transition: none;
}

.picker-frame {
  margin: 1.5rem 1.5rem;
}

.picker-holder {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.38);
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  pointer-events: none;
  transition-delay: 0.375s;
}

@media (min-width: 576px) {
  .picker-holder {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .picker-holder {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker-holder {
    transition: none;
  }
}

@media (min-width: 576px) {
  .picker-holder {
    transition-delay: 0.4875s;
  }
}

@media (min-width: 992px) {
  .picker-holder {
    transition-delay: 0.25s;
  }
}

.picker-opened .picker-holder {
  opacity: 1;
  transition-delay: 0s;
}

.picker-wrap {
  border-radius: 4px;
  transition-duration: 0.375s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.428572;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  max-width: 18.5rem;
  opacity: 0;
  outline: 0;
  pointer-events: auto;
  position: relative;
  transform: scale(0.87);
}

@media (min-width: 576px) {
  .picker-wrap {
    transition-duration: 0.4875s;
  }
}

@media (min-width: 992px) {
  .picker-wrap {
    transition-duration: 0.25s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker-wrap {
    transition: none;
  }
}

@media (orientation: landscape) {
  .picker-wrap {
    display: flex;
    max-width: none;
  }
}

.picker-opened .picker-wrap {
  opacity: 1;
  transform: scale(1);
}

.picker-footer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem;
}

@media (orientation: landscape) {
  .picker-footer {
    border-radius: 0 0 4px 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.picker-footer button {
  flex: 0 1 auto;
  margin-left: 0.5rem;
  min-width: 0;
}

.picker-footer button:first-child {
  margin-left: 0;
}

.picker-header {
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-right: 3rem;
  padding-left: 3rem;
  position: relative;
  text-align: center;
}

@media (orientation: landscape) {
  .picker-header {
    margin-top: 0.5rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
}

.picker-month,
.picker-year {
  display: inline;
  margin-left: 0.5rem;
}

.picker-month:first-child,
.picker-year:first-child {
  margin-left: 0;
}

.picker-nav-next,
.picker-nav-prev {
  height: 2.5rem;
  margin-top: -1.25rem;
  position: absolute;
  top: 50%;
  width: 2.5rem;
}

.picker-nav-next, .picker-nav-next.material-icons,
.picker-nav-prev,
.picker-nav-prev.material-icons {
  line-height: 2.5rem;
}

.picker-nav-next {
  right: 0;
}

.picker-nav-next::before {
  content: "keyboard_arrow_right";
}

.picker-nav-prev {
  left: 0;
}

.picker-nav-prev::before {
  content: "keyboard_arrow_left";
}

.picker-date-display {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
  padding: 1rem 1.5rem;
}

@media (orientation: landscape) {
  .picker-date-display {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    min-width: 9.75rem;
    white-space: nowrap;
  }
}

.picker-date-display-bottom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.176471;
}

.picker-day-display {
  margin-right: 0.5rem;
}

.picker-weekday-display {
  margin-right: 0.5rem;
}

@media (orientation: landscape) {
  .picker-weekday-display {
    display: block;
    margin-right: 0;
  }
}

.picker-weekday-display::after {
  content: ',';
}

.picker-box {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
}

@media (orientation: landscape) {
  .picker-box {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
}

.picker-input.form-control[readonly] {
  border-bottom-style: solid;
  color: inherit;
  cursor: text;
}

.picker-input.picker-input-active {
  border-bottom-color: #2196f3;
}

.picker-day {
  border-radius: 50%;
  cursor: default;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: auto;
  vertical-align: middle;
  width: 2.5rem;
}

@media (orientation: landscape) {
  .picker-day {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
}

.picker-day.picker-day-selected {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.picker-day-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.picker-day-outfocus {
  display: none;
}

.picker-day-today {
  color: #ffe600;
  font-weight: bolder;
}

.picker-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0.5rem;
  table-layout: fixed;
}

.picker-table td,
.picker-table th {
  border: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.picker-table th {
  font-weight: inherit;
}

.picker-weekday {
  color: rgba(0, 0, 0, 0.38);
  height: 2.5rem;
  vertical-align: middle;
  width: 2.5rem;
}

.picker-select-month,
.picker-select-year {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: rgba(0, 0, 0, 0.12);
  background-image: none;
  background-position: 100% 50%;
  background-size: auto 100%;
  border: 0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-size: inherit;
  height: 1.25rem;
  line-height: 1.25rem;
  opacity: 0.7;
  padding: 0 1.25rem 0 0.5rem;
}

@media (min-width: 576px) {
  .picker-select-month,
  .picker-select-year {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .picker-select-month,
  .picker-select-year {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker-select-month,
  .picker-select-year {
    transition: none;
  }
}

.picker-select-month:focus, .picker-select-month:hover,
.picker-select-year:focus,
.picker-select-year:hover {
  opacity: 1;
}

@-moz-document url-prefix('') {
  .picker-select-month,
  .picker-select-year {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
  }
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .picker-select-month,
  .picker-select-year {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
  }
}

.picker-select-month:focus,
.picker-select-year:focus {
  outline: 0;
}

.picker-select-month {
  margin-left: 0.5rem;
}

.progress {
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.progress-bar {
  border-bottom: 0.25rem solid #3f51b5;
}

.progress-bar.bg-primary {
  background-color: transparent !important;
  border-bottom-color: #ffe600;
}

.progress-bar.bg-primary::after {
  background-color: #ffff55;
}

.progress-bar.bg-secondary {
  background-color: transparent !important;
  border-bottom-color: #2196f3;
}

.progress-bar.bg-secondary::after {
  background-color: #6ec6ff;
}

.progress-bar.bg-danger {
  background-color: transparent !important;
  border-bottom-color: #f44336;
}

.progress-bar.bg-danger::after {
  background-color: #ffcdd2;
}

.progress-bar.bg-info {
  background-color: transparent !important;
  border-bottom-color: #2196f3;
}

.progress-bar.bg-info::after {
  background-color: #bbdefb;
}

.progress-bar.bg-success {
  background-color: transparent !important;
  border-bottom-color: #4caf50;
}

.progress-bar.bg-success::after {
  background-color: #c8e6c9;
}

.progress-bar.bg-warning {
  background-color: transparent !important;
  border-bottom-color: #ff9800;
}

.progress-bar.bg-warning::after {
  background-color: #ffe0b2;
}

.progress-bar.bg-dark {
  background-color: transparent !important;
  border-bottom-color: #424242;
}

.progress-bar.bg-dark::after {
  background-color: #757575;
}

.progress-bar.bg-light {
  background-color: transparent !important;
  border-bottom-color: #f5f5f5;
}

.progress-bar.bg-light::after {
  background-color: #fafafa;
}

.progress-bar::after {
  background-color: #c5cae9;
  content: '';
  display: block;
  height: 0.25rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.progress-bar-animated::before {
  animation-direction: reverse;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar-animation;
  animation-timing-function: linear;
}

@media (min-width: 576px) {
  .progress-bar-animated::before {
    animation-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .progress-bar-animated::before {
    animation-duration: 0.2s;
  }
}

.progress-bar-animated,
.progress-bar-striped {
  box-sizing: content-box;
  position: relative;
}

.progress-bar-animated.bg-primary::before,
.progress-bar-striped.bg-primary::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #ffff55, #ffff55 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #ffff55, #ffff55 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #ffff55, #ffff55 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-secondary::before,
.progress-bar-striped.bg-secondary::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #6ec6ff, #6ec6ff 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #6ec6ff, #6ec6ff 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #6ec6ff, #6ec6ff 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-danger::before,
.progress-bar-striped.bg-danger::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #ffcdd2, #ffcdd2 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #ffcdd2, #ffcdd2 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #ffcdd2, #ffcdd2 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-info::before,
.progress-bar-striped.bg-info::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #bbdefb, #bbdefb 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #bbdefb, #bbdefb 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #bbdefb, #bbdefb 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-success::before,
.progress-bar-striped.bg-success::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #c8e6c9, #c8e6c9 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #c8e6c9, #c8e6c9 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #c8e6c9, #c8e6c9 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-warning::before,
.progress-bar-striped.bg-warning::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #ffe0b2, #ffe0b2 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #ffe0b2, #ffe0b2 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #ffe0b2, #ffe0b2 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-dark::before,
.progress-bar-striped.bg-dark::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-light::before,
.progress-bar-striped.bg-light::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated::after,
.progress-bar-striped::after {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  right: -1.5rem;
  bottom: -0.25rem;
}

.progress-bar-animated::before,
.progress-bar-striped::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #c5cae9, #c5cae9 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #c5cae9, #c5cae9 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #c5cae9, #c5cae9 0.125rem, transparent 0.125rem, transparent 100%);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 0.75rem 0.75rem;
  content: '';
  display: block;
  height: 0.25rem;
  position: absolute;
  right: -100vw;
  bottom: -0.25rem;
  left: 0;
  z-index: -1;
}

@keyframes progress-bar-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0.75rem 0;
  }
}

.progress-bar-indeterminate {
  border-bottom-color: #c5cae9;
  position: relative;
  width: 100%;
}

.progress-bar-indeterminate.bg-primary {
  border-bottom-color: #ffff55;
}

.progress-bar-indeterminate.bg-primary::after, .progress-bar-indeterminate.bg-primary::before {
  background-color: #ffe600;
}

.progress-bar-indeterminate.bg-primary::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-secondary {
  border-bottom-color: #6ec6ff;
}

.progress-bar-indeterminate.bg-secondary::after, .progress-bar-indeterminate.bg-secondary::before {
  background-color: #2196f3;
}

.progress-bar-indeterminate.bg-secondary::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-danger {
  border-bottom-color: #ffcdd2;
}

.progress-bar-indeterminate.bg-danger::after, .progress-bar-indeterminate.bg-danger::before {
  background-color: #f44336;
}

.progress-bar-indeterminate.bg-danger::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-info {
  border-bottom-color: #bbdefb;
}

.progress-bar-indeterminate.bg-info::after, .progress-bar-indeterminate.bg-info::before {
  background-color: #2196f3;
}

.progress-bar-indeterminate.bg-info::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-success {
  border-bottom-color: #c8e6c9;
}

.progress-bar-indeterminate.bg-success::after, .progress-bar-indeterminate.bg-success::before {
  background-color: #4caf50;
}

.progress-bar-indeterminate.bg-success::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-warning {
  border-bottom-color: #ffe0b2;
}

.progress-bar-indeterminate.bg-warning::after, .progress-bar-indeterminate.bg-warning::before {
  background-color: #ff9800;
}

.progress-bar-indeterminate.bg-warning::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-dark {
  border-bottom-color: #757575;
}

.progress-bar-indeterminate.bg-dark::after, .progress-bar-indeterminate.bg-dark::before {
  background-color: #424242;
}

.progress-bar-indeterminate.bg-dark::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-light {
  border-bottom-color: #fafafa;
}

.progress-bar-indeterminate.bg-light::after, .progress-bar-indeterminate.bg-light::before {
  background-color: #f5f5f5;
}

.progress-bar-indeterminate.bg-light::before {
  background-image: none;
}

.progress-bar-indeterminate::after, .progress-bar-indeterminate::before {
  border-radius: 0.25rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-color: #3f51b5;
  width: 0%;
}

.progress-bar-indeterminate::after {
  animation-name: progress-bar-indeterminate-after;
  bottom: -0.25rem;
  z-index: 0;
}

.progress-bar-indeterminate::before {
  animation-name: progress-bar-indeterminate-before;
  content: '';
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: -0.25rem;
  z-index: 0;
}

@keyframes progress-bar-indeterminate-after {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@keyframes progress-bar-indeterminate-before {
  0%,
  62.5% {
    left: 0%;
    width: 0%;
  }
  71.875% {
    left: 0%;
    width: 25%;
  }
  81.25% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 25%;
  }
}

.progress-circular {
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.progress-circular-gap {
  border-top: 0.125rem solid #3f51b5;
  position: absolute;
  top: 0;
  right: 1.1875rem;
  bottom: 0;
  left: 1.1875rem;
}

.progress-circular-inner {
  animation: progress-circular-inner-rotate 5.332s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.progress-circular-left,
.progress-circular-right {
  height: 2.5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1.25rem;
}

.progress-circular-left {
  left: 0;
}

.progress-circular-right {
  right: 0;
}

.progress-circular-spinner {
  border: 0.25rem solid #3f51b5;
  border-bottom-color: transparent;
  border-radius: 50%;
  height: 2.5rem;
  position: absolute;
  top: 0;
  width: 2.5rem;
}

.progress-circular-left .progress-circular-spinner {
  animation: progress-circular-spinner-left 1.333s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-right-color: transparent;
  left: 0;
}

.progress-circular-right .progress-circular-spinner {
  animation: progress-circular-spinner-right 1.333s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-left-color: transparent;
  right: 0;
}

.progress-circular-wrapper {
  animation: progress-circular-wrapper-rotate 2.666s linear infinite;
}

@keyframes progress-circular-inner-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes progress-circular-spinner-left {
  0%,
  100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes progress-circular-spinner-right {
  0%,
  100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes progress-circular-wrapper-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.progress-circular-primary .progress-circular-gap,
.progress-circular-primary .progress-circular-spinner {
  border-top-color: #ffe600;
}

.progress-circular-primary .progress-circular-left .progress-circular-spinner {
  border-left-color: #ffe600;
}

.progress-circular-primary .progress-circular-right .progress-circular-spinner {
  border-right-color: #ffe600;
}

.progress-circular-secondary .progress-circular-gap,
.progress-circular-secondary .progress-circular-spinner {
  border-top-color: #2196f3;
}

.progress-circular-secondary .progress-circular-left .progress-circular-spinner {
  border-left-color: #2196f3;
}

.progress-circular-secondary .progress-circular-right .progress-circular-spinner {
  border-right-color: #2196f3;
}

.progress-circular-danger .progress-circular-gap,
.progress-circular-danger .progress-circular-spinner {
  border-top-color: #f44336;
}

.progress-circular-danger .progress-circular-left .progress-circular-spinner {
  border-left-color: #f44336;
}

.progress-circular-danger .progress-circular-right .progress-circular-spinner {
  border-right-color: #f44336;
}

.progress-circular-info .progress-circular-gap,
.progress-circular-info .progress-circular-spinner {
  border-top-color: #2196f3;
}

.progress-circular-info .progress-circular-left .progress-circular-spinner {
  border-left-color: #2196f3;
}

.progress-circular-info .progress-circular-right .progress-circular-spinner {
  border-right-color: #2196f3;
}

.progress-circular-success .progress-circular-gap,
.progress-circular-success .progress-circular-spinner {
  border-top-color: #4caf50;
}

.progress-circular-success .progress-circular-left .progress-circular-spinner {
  border-left-color: #4caf50;
}

.progress-circular-success .progress-circular-right .progress-circular-spinner {
  border-right-color: #4caf50;
}

.progress-circular-warning .progress-circular-gap,
.progress-circular-warning .progress-circular-spinner {
  border-top-color: #ff9800;
}

.progress-circular-warning .progress-circular-left .progress-circular-spinner {
  border-left-color: #ff9800;
}

.progress-circular-warning .progress-circular-right .progress-circular-spinner {
  border-right-color: #ff9800;
}

.progress-circular-dark .progress-circular-gap,
.progress-circular-dark .progress-circular-spinner {
  border-top-color: #424242;
}

.progress-circular-dark .progress-circular-left .progress-circular-spinner {
  border-left-color: #424242;
}

.progress-circular-dark .progress-circular-right .progress-circular-spinner {
  border-right-color: #424242;
}

.progress-circular-light .progress-circular-gap,
.progress-circular-light .progress-circular-spinner {
  border-top-color: #f5f5f5;
}

.progress-circular-light .progress-circular-left .progress-circular-spinner {
  border-left-color: #f5f5f5;
}

.progress-circular-light .progress-circular-right .progress-circular-spinner {
  border-right-color: #f5f5f5;
}

.custom-control {
  display: block;
  min-height: 1.250001rem;
  padding-left: 2.25rem;
  position: relative;
}

.custom-control + .custom-control {
  margin-top: 0.75rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1.5rem;
}

.custom-control-inline + .custom-control-inline {
  margin-top: 0;
}

.custom-control-label {
  color: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  margin-bottom: 0;
}

.custom-control-label::after {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: -0.125rem;
  left: 0;
}

.custom-control-label::before {
  transition-duration: 0.3s;
  transition-property: background-color, opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: currentColor;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  content: '';
  display: block;
  height: 3rem;
  margin-top: -0.875rem;
  margin-left: -0.75rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.87, 0.87) translateZ(0);
  width: 3rem;
}

@media (min-width: 576px) {
  .custom-control-label::before {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-control-label::before {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-control-input.focus ~ .custom-control-label::before, .custom-control-input:active ~ .custom-control-label::before {
  opacity: 0.12;
  transform: scale(1, 1) translateZ(0);
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #2196f3;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2196f3;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.26);
}

.custom-control-input:disabled ~ .custom-control-label::after {
  color: rgba(0, 0, 0, 0.26);
}

.custom-control-input:disabled ~ .custom-control-label::before {
  display: none;
}

.custom-checkbox .custom-control-label::after {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "check_box_outline_blank";
  line-height: 1;
  vertical-align: middle;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "check_box";
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  content: "indeterminate_check_box";
}

.custom-radio .custom-control-label::after {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "radio_button_unchecked";
  line-height: 1;
  vertical-align: middle;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "radio_button_checked";
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label {
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .custom-switch .custom-control-label {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-switch .custom-control-label {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label {
    transition: none;
  }
}

.custom-switch .custom-control-label::after {
  transition-duration: 0.3s;
  transition-property: background-color, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.54);
  content: '';
  display: block;
  height: 1.5rem;
  position: absolute;
  width: 1.5rem;
}

@media (min-width: 576px) {
  .custom-switch .custom-control-label::after {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-switch .custom-control-label::after {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after, .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  transform: translateX(1.5rem);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #2196f3;
}

.custom-switch .custom-control-input:checked ~ .custom-control-track {
  background-color: rgba(33, 150, 243, 0.5);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  background-color: #bdbdbd;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-track {
  background-color: rgba(0, 0, 0, 0.12);
}

.custom-switch .custom-control-track {
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0.38);
  border: 0.25rem solid transparent;
  border-radius: 1rem;
  content: '';
  display: block;
  height: 1.5rem;
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 3rem;
}

@media (min-width: 576px) {
  .custom-switch .custom-control-track {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-switch .custom-control-track {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-track {
    transition: none;
  }
}

.custom-range {
  appearance: none;
  background-color: transparent;
  padding-left: 0;
  width: 100%;
}

.custom-range:active::-moz-range-track, .custom-range:focus::-moz-range-track {
  background-color: rgba(0, 0, 0, 0.38);
}

.custom-range:active::-ms-fill-upper, .custom-range:focus::-ms-fill-upper {
  background-color: rgba(0, 0, 0, 0.38);
}

.custom-range:active::-webkit-slider-runnable-track, .custom-range:focus::-webkit-slider-runnable-track {
  background-color: rgba(0, 0, 0, 0.38);
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.75rem rgba(33, 150, 243, 0.12);
}

.custom-range:focus::-ms-range-thumb {
  box-shadow: 0 0 0 0.75rem rgba(33, 150, 243, 0.12);
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.75rem rgba(33, 150, 243, 0.12);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-moz-range-progress {
  background-color: #2196f3;
}

.custom-range::-moz-range-thumb {
  transition-duration: 0.3s;
  transition-property: box-shadow, height, width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
}

@media (min-width: 576px) {
  .custom-range::-moz-range-thumb {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-moz-range-thumb {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  box-shadow: none;
  height: 1.5rem;
  width: 1.5rem;
}

.custom-range::-moz-range-thumb:focus {
  outline: 0;
}

.custom-range::-moz-range-track {
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.26);
  border-color: transparent;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  height: 0.125rem;
  width: 100%;
}

@media (min-width: 576px) {
  .custom-range::-moz-range-track {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-moz-range-track {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-track {
    transition: none;
  }
}

.custom-range::-ms-fill-lower {
  background-color: #2196f3;
  height: 0.125rem;
  margin-bottom: 0.125rem;
  margin-left: 0.375rem;
}

.custom-range::-ms-fill-upper {
  background-color: rgba(0, 0, 0, 0.26);
  height: 0.125rem;
  margin-right: 0.375rem;
  margin-bottom: 0.125rem;
}

.custom-range::-ms-thumb {
  transition-duration: 0.3s;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  height: 0.75rem;
  margin-right: 0.375rem;
  margin-left: 0.375rem;
  width: 0.75rem;
}

@media (min-width: 576px) {
  .custom-range::-ms-thumb {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-ms-thumb {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  box-shadow: 0 0 0 0.375rem #2196f3;
}

.custom-range::-ms-thumb:focus {
  outline: 0;
}

.custom-range::-ms-track {
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  border-color: transparent;
  border-width: 0.875rem 0;
  color: transparent;
  cursor: pointer;
  height: 0.125rem;
  width: 100%;
}

@media (min-width: 576px) {
  .custom-range::-ms-track {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-ms-track {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-track {
    transition: none;
  }
}

.custom-range::-webkit-slider-runnable-track {
  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.26);
  border-color: transparent;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  height: 0.125rem;
  width: 100%;
}

@media (min-width: 576px) {
  .custom-range::-webkit-slider-runnable-track {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-webkit-slider-runnable-track {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-runnable-track {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb {
  transition-duration: 0.3s;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  height: 0.75rem;
  margin-top: -0.3125rem;
  width: 0.75rem;
}

@media (min-width: 576px) {
  .custom-range::-webkit-slider-thumb {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-range::-webkit-slider-thumb {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 0.375rem #2196f3;
}

.custom-range::-webkit-slider-thumb:focus {
  outline: 0;
}

.snackbar {
  align-items: center;
  background-color: #323232;
  color: white;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.428572;
  opacity: 0;
  padding: 0.875rem 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: opacity 0s 0.195s, transform 0.195s cubic-bezier(0.4, 0, 1, 1);
  width: 100%;
  z-index: 60;
}

@media (min-width: 576px) {
  .snackbar {
    border-radius: 4px;
    max-width: 35.5rem;
    min-width: 18rem;
    left: 50%;
    transform: translate(-50%, 100%);
    width: auto;
  }
}

@media (min-width: 576px) {
  .snackbar {
    transition: opacity 0s 0.2535s, transform 0.2535s cubic-bezier(0.4, 0, 1, 1);
  }
}

@media (min-width: 992px) {
  .snackbar {
    transition: opacity 0s 0.13s, transform 0.13s cubic-bezier(0.4, 0, 1, 1);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .snackbar {
    transition: none;
  }
}

.snackbar.show {
  transition-duration: 0.225s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 576px) {
  .snackbar.show {
    transition-duration: 0.2925s;
  }
}

@media (min-width: 992px) {
  .snackbar.show {
    transition-duration: 0.15s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .snackbar.show {
    transition: none;
  }
}

@media (min-width: 576px) {
  .snackbar.show {
    transform: translate(-50%, 0);
  }
}

.snackbar-body {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  max-height: 100%;
  min-width: 0;
}

.snackbar-btn {
  transition-duration: 0.3s;
  transition-property: background-color, background-image;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  background-image: none;
  border: 0;
  color: #2196f3;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  margin-left: 1.5rem;
  padding: 0;
  text-transform: uppercase;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .snackbar-btn {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .snackbar-btn {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .snackbar-btn {
    transition: none;
  }
}

.snackbar-btn:focus, .snackbar-btn:hover {
  color: #6ec6ff;
  text-decoration: none;
}

@media (min-width: 576px) {
  .snackbar-btn {
    margin-left: 3rem;
  }
}

.snackbar-btn:focus {
  outline: 0;
}

@media (min-width: 576px) {
  .snackbar-left,
  .snackbar-right {
    transform: translateY(100%);
  }
  .snackbar-left.show,
  .snackbar-right.show {
    transform: translateY(-1.5rem);
  }
}

@media (min-width: 576px) {
  .snackbar-left {
    left: 1.5rem;
  }
}

@media (min-width: 576px) {
  .snackbar-right {
    right: 1.5rem;
    left: auto;
  }
}

.snackbar-multi-line {
  height: 5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.snackbar-multi-line .snackbar-body {
  white-space: normal;
}

.stepper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  padding: 1.5rem 1.5rem;
  position: relative;
}

.stepper::after, .stepper::before {
  border-top: 1px solid #bdbdbd;
  content: '';
  display: block;
  position: absolute;
}

.stepper:first-child::before {
  display: none;
}

.stepper:last-child::after {
  display: none;
}

.stepper-horiz {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.stepper-horiz::before {
  border-top: 1px solid #bdbdbd;
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
}

.stepper-horiz .stepper::after, .stepper-horiz .stepper::before {
  border-top: 1px solid #bdbdbd;
  top: 50%;
  width: 1rem;
}

.stepper-horiz .stepper::after {
  right: 0;
}

.stepper-horiz .stepper::before {
  left: 0;
}

.stepper-vert {
  background-color: #ffffff;
  position: relative;
}

.stepper-vert .stepper::after, .stepper-vert .stepper::before {
  border-left: 1px solid #bdbdbd;
  height: 1rem;
  left: 2.25rem;
}

.stepper-vert .stepper::after {
  bottom: 0;
}

.stepper-vert .stepper::before {
  top: 0;
}

.stepper-icon {
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 1.5rem;
}

.stepper.active .stepper-icon,
.stepper.done .stepper-icon {
  background-color: #ffe600;
  color: rgba(0, 0, 0, 0.87);
}

.stepper-icon .material-icons {
  font-size: 1.333em;
}

.stepper-text {
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
}

.stepper.active .stepper-text,
.stepper.done .stepper-text {
  color: rgba(0, 0, 0, 0.87);
}

.stepper.active .stepper-text {
  font-weight: bolder;
}

.nav-tabs {
  box-shadow: inset 0 -2px 0 -1px rgba(0, 0, 0, 0.12);
}

.nav-tabs.border-0, .nav-tabs.border-bottom-0 {
  box-shadow: none;
}

.nav-tabs .nav-link {
  transition-duration: 0.3s;
  transition-property: background-color, color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  min-height: 3rem;
  opacity: 0.7;
  padding: 1.0625rem 0.75rem;
  position: relative;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .nav-tabs .nav-link {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-link {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-tabs .nav-link {
    transition: none;
  }
}

.nav-tabs .nav-link:active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link:active {
  opacity: 1;
}

.nav-tabs .nav-link.active {
  color: #2196f3;
}

.nav-tabs .nav-link.active::before {
  opacity: 1;
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.nav-tabs .nav-link::before {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #2196f3;
  content: '';
  display: block;
  height: 0.125rem;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .nav-tabs .nav-link::before {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-link::before {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-tabs .nav-link::before {
    transition: none;
  }
}

.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.nav-tabs-material {
  position: relative;
}

.nav-tabs-material.animate .nav-link::before {
  opacity: 0;
}

.nav-tabs-material.animate .nav-tabs-indicator {
  transition-duration: 0.3s;
  transition-property: left, right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .nav-tabs-material.animate .nav-tabs-indicator {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .nav-tabs-material.animate .nav-tabs-indicator {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-tabs-material.animate .nav-tabs-indicator {
    transition: none;
  }
}

.nav-tabs-material .nav-link::before {
  transition: none;
}

.nav-tabs-material .nav-tabs-indicator {
  background-color: #2196f3;
  display: none;
  height: 0.125rem;
  position: absolute;
  bottom: 0;
}

.nav-tabs-material .nav-tabs-indicator.show {
  display: block;
}

.nav-tabs-scrollable .nav-tabs-material .nav-tabs-indicator {
  bottom: 3rem;
}

.nav-tabs-scrollable {
  box-shadow: inset 0 -2px 0 -1px rgba(0, 0, 0, 0.12);
  height: 3rem;
  overflow: hidden;
}

.nav-tabs-scrollable .nav-tabs {
  box-shadow: none;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 3rem;
}

.nav-tabs-scrollable .nav-tabs::-webkit-scrollbar {
  display: none;
}

.form-control, .custom-select, .form-control-file {
  background-clip: padding-box;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0 calc(0.375rem - 1px);
  width: 100%;
}

.form-control:hover, .custom-select:hover, .form-control-file:hover {
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: inset 0 -2px 0 -1px rgba(0, 0, 0, 0.87);
}

.form-control::-ms-expand, .custom-select::-ms-expand, .form-control-file::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control::placeholder, .custom-select::placeholder, .form-control-file::placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.form-control:disabled, .custom-select:disabled, .form-control-file:disabled, .form-control[readonly], .custom-select[readonly], .form-control-file[readonly] {
  border-style: dotted;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.form-control:disabled:focus, .custom-select:disabled:focus, .form-control-file:disabled:focus, .form-control:disabled:hover, .custom-select:disabled:hover, .form-control-file:disabled:hover, .form-control[readonly]:focus, .custom-select[readonly]:focus, .form-control-file[readonly]:focus, .form-control[readonly]:hover, .custom-select[readonly]:hover, .form-control-file[readonly]:hover {
  border-color: rgba(0, 0, 0, 0.42);
  box-shadow: none;
}

.form-control:focus, .custom-select:focus, .form-control-file:focus {
  border-color: #2196f3;
  box-shadow: inset 0 -2px 0 -1px #2196f3;
  outline: 0;
}

.form-control:invalid:required, .custom-select:invalid:required, .form-control-file:invalid:required {
  outline: 0;
}

.form-control[type='file'] {
  max-height: 2.25rem;
}

.form-control-lg {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.form-control-lg[type='file'] {
  max-height: 3.75rem;
}

.form-control-sm {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.form-control-sm[type='file'] {
  max-height: 2rem;
}

select.form-control, .custom-select {
  appearance: none;
}

@-moz-document url-prefix('') {
  select.form-control, .custom-select {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 1.5em;
  }
  select.form-control[multiple], .custom-select[multiple], select.form-control[size]:not([size='1']), .custom-select[size]:not([size='1']) {
    background-image: none;
  }
}

@media (-webkit-min-device-pixel-ratio: 0) {
  select.form-control, .custom-select {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 1.5em;
  }
  select.form-control[multiple], .custom-select[multiple], select.form-control[size]:not([size='1']), .custom-select[size]:not([size='1']) {
    background-image: none;
  }
}

select.form-control[multiple], select.form-control[size]:not([size='1']), textarea.form-control:not([rows='1']), .custom-select[multiple], .custom-select[size]:not([size='1']) {
  border-radius: 4px;
  border-width: 1px;
  min-height: 3.5rem;
  padding: calc(1rem - 1px) 1rem;
}

select.form-control:hover[multiple], select.form-control:hover[size]:not([size='1']), textarea.form-control:hover:not([rows='1']), .custom-select:hover[multiple], .custom-select:hover[size]:not([size='1']) {
  box-shadow: inset 2px 2px 0 -1px rgba(0, 0, 0, 0.87), inset -2px -2px 0 -1px rgba(0, 0, 0, 0.87);
}

select.form-control:focus[multiple], select.form-control:focus[size]:not([size='1']), textarea.form-control:focus:not([rows='1']), .custom-select:focus[multiple], .custom-select:focus[size]:not([size='1']) {
  box-shadow: inset 2px 2px 0 -1px #2196f3, inset -2px -2px 0 -1px #2196f3;
}

select.form-control-lg[multiple], select.form-control-lg[size]:not([size='1']) {
  padding: calc(0.875rem - 1px) 1rem;
}

select.form-control-sm[multiple], select.form-control-sm[size]:not([size='1']) {
  padding: calc(0.75rem - 1px) 0.75rem;
}

textarea.form-control {
  min-height: 2.25rem;
}

textarea.form-control-lg {
  min-height: 3.75rem;
}

textarea.form-control-lg:not([rows='1']) {
  min-height: 4.25rem;
  padding: calc(0.875rem - 1px) 1rem;
}

textarea.form-control-sm {
  min-height: 2rem;
}

textarea.form-control-sm:not([rows='1']) {
  min-height: 2.75rem;
  padding: calc(0.75rem - 1px) 0.75rem;
}

.custom-file {
  display: inline-block;
  height: 2.25rem;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: 2.25rem;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.custom-file-input:focus ~ .custom-file-label, .custom-file-input:hover ~ .custom-file-label {
  border-bottom-color: #2196f3;
  box-shadow: inset 0 -2px 0 -1px #2196f3;
}

.custom-file-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition-duration: 0.3s;
  transition-property: border-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  color: rgba(0, 0, 0, 0.38);
  font-size: 1rem;
  height: 2.25rem;
  line-height: 1.5;
  padding: 0.375rem 2.25rem calc(0.375rem - 1px) 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 576px) {
  .custom-file-label {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .custom-file-label {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-file-label {
    transition: none;
  }
}

.custom-file-label::after {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "attachment";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.custom-select-lg {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 1.5em calc(0.625rem - 1px) 0;
}

.custom-select-lg[multiple], .custom-select-lg[size]:not([size='1']) {
  padding: calc(0.875rem - 1px) 1rem;
}

.custom-select-sm {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 1.5em calc(0.375rem - 1px) 0;
}

.custom-select-sm[multiple], .custom-select-sm[size]:not([size='1']) {
  padding: calc(0.75rem - 1px) 0.75rem;
}

.form-control-file {
  max-height: 2.25rem;
}

.form-control-range {
  display: block;
  width: 100%;
}

.invalid-feedback {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: #f44336;
  display: none;
  margin-top: 0.5rem;
  width: 100%;
}

.form-control-lg + .invalid-feedback {
  margin-top: 0.75rem;
}

.form-control-sm + .invalid-feedback {
  margin-top: 0.25rem;
}

.invalid-tooltip {
  border-radius: 4px;
  background-color: #f44336;
  color: white;
  display: none;
  font-size: 0.875rem;
  line-height: 1.428572;
  margin-top: 0.5rem;
  max-width: 100%;
  opacity: 0.9;
  padding: 0.375rem 1rem;
  position: absolute;
  top: 100%;
  text-align: center;
  word-break: break-word;
  z-index: 240;
}

@media (min-width: 768px) {
  .invalid-tooltip {
    font-size: 0.625rem;
    padding: 0.241071rem 0.5rem;
  }
}

.form-control-lg + .invalid-tooltip {
  margin-top: 0.75rem;
}

.form-control-sm + .invalid-tooltip {
  margin-top: 0.25rem;
}

.custom-control-input.is-invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label::after {
  color: #f44336;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #f44336;
}

.custom-control-input.is-invalid ~ .custom-control-track,
.was-validated .custom-control-input:invalid ~ .custom-control-track {
  background-color: rgba(244, 67, 54, 0.5);
}

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:hover ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:hover ~ .custom-file-label {
  border-bottom-color: #f44336;
  box-shadow: inset 0 -2px 0 -1px #f44336;
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-bottom-color: #f44336;
}

.custom-file-input.is-invalid ~ .custom-file-label:hover,
.was-validated .custom-file-input:invalid ~ .custom-file-label:hover {
  border-bottom-color: #f44336;
  box-shadow: inset 0 -2px 0 -1px #f44336;
}

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-switch .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-switch .custom-control-input:invalid ~ .custom-control-label::after {
  background-color: #f44336;
}

.form-check-input.is-invalid + .form-check-label,
.was-validated .form-check-input:invalid + .form-check-label {
  color: #f44336;
}

.is-invalid.form-control, .is-invalid.custom-select, .is-invalid.form-control-file,
.was-validated .form-control:invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control-file:invalid {
  border-color: #f44336;
}

.is-invalid.form-control:focus, .is-invalid.custom-select:focus, .is-invalid.form-control-file:focus, .is-invalid.form-control:hover, .is-invalid.custom-select:hover, .is-invalid.form-control-file:hover,
.was-validated .form-control:invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control-file:invalid:focus,
.was-validated .form-control:invalid:hover,
.was-validated .custom-select:invalid:hover,
.was-validated .form-control-file:invalid:hover {
  border-color: #f44336;
  box-shadow: inset 0 -2px 0 -1px #f44336;
}

.is-invalid.form-control ~ .invalid-feedback, .is-invalid.custom-select ~ .invalid-feedback, .is-invalid.form-control-file ~ .invalid-feedback,
.is-invalid.form-control ~ .invalid-tooltip,
.is-invalid.custom-select ~ .invalid-tooltip,
.is-invalid.form-control-file ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}

select.is-invalid.form-control:focus[multiple], select.is-invalid.form-control:focus[size]:not([size='1']), textarea.is-invalid.form-control:focus:not([rows='1']), .is-invalid.custom-select:focus[multiple], .is-invalid.custom-select:focus[size]:not([size='1']), select.is-invalid.form-control:hover[multiple], select.is-invalid.form-control:hover[size]:not([size='1']), textarea.is-invalid.form-control:hover:not([rows='1']), .is-invalid.custom-select:hover[multiple], .is-invalid.custom-select:hover[size]:not([size='1']),
.was-validated select.form-control:invalid:focus[multiple],
.was-validated select.form-control:invalid:focus[size]:not([size='1']),
.was-validated textarea.form-control:invalid:focus:not([rows='1']),
.was-validated .custom-select:invalid:focus[multiple],
.was-validated .custom-select:invalid:focus[size]:not([size='1']),
.was-validated select.form-control:invalid:hover[multiple],
.was-validated select.form-control:invalid:hover[size]:not([size='1']),
.was-validated textarea.form-control:invalid:hover:not([rows='1']),
.was-validated .custom-select:invalid:hover[multiple],
.was-validated .custom-select:invalid:hover[size]:not([size='1']) {
  box-shadow: inset 2px 2px 0 -1px #f44336, inset -2px -2px 0 -1px #f44336;
}

.textfield-box select.is-invalid.form-control:focus[multiple], .textfield-box select.is-invalid.form-control:focus[size]:not([size='1']), .textfield-box textarea.is-invalid.form-control:focus:not([rows='1']), .textfield-box .is-invalid.custom-select:focus[multiple], .textfield-box .is-invalid.custom-select:focus[size]:not([size='1']), .textfield-box select.is-invalid.form-control:hover[multiple], .textfield-box select.is-invalid.form-control:hover[size]:not([size='1']), .textfield-box textarea.is-invalid.form-control:hover:not([rows='1']), .textfield-box .is-invalid.custom-select:hover[multiple], .textfield-box .is-invalid.custom-select:hover[size]:not([size='1']),
.was-validated .textfield-box select.form-control:invalid:focus[multiple],
.was-validated .textfield-box select.form-control:invalid:focus[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:invalid:focus:not([rows='1']),
.was-validated .textfield-box .custom-select:invalid:focus[multiple],
.was-validated .textfield-box .custom-select:invalid:focus[size]:not([size='1']),
.was-validated .textfield-box select.form-control:invalid:hover[multiple],
.was-validated .textfield-box select.form-control:invalid:hover[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:invalid:hover:not([rows='1']),
.was-validated .textfield-box .custom-select:invalid:hover[multiple],
.was-validated .textfield-box .custom-select:invalid:hover[size]:not([size='1']) {
  box-shadow: inset 0 -2px 0 -1px #f44336;
}

.valid-feedback {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: #4caf50;
  display: none;
  margin-top: 0.5rem;
  width: 100%;
}

.form-control-lg + .valid-feedback {
  margin-top: 0.75rem;
}

.form-control-sm + .valid-feedback {
  margin-top: 0.25rem;
}

.valid-tooltip {
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  display: none;
  font-size: 0.875rem;
  line-height: 1.428572;
  margin-top: 0.5rem;
  max-width: 100%;
  opacity: 0.9;
  padding: 0.375rem 1rem;
  position: absolute;
  top: 100%;
  text-align: center;
  word-break: break-word;
  z-index: 240;
}

@media (min-width: 768px) {
  .valid-tooltip {
    font-size: 0.625rem;
    padding: 0.241071rem 0.5rem;
  }
}

.form-control-lg + .valid-tooltip {
  margin-top: 0.75rem;
}

.form-control-sm + .valid-tooltip {
  margin-top: 0.25rem;
}

.custom-control-input.is-valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label::after,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label::after {
  color: #4caf50;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #4caf50;
}

.custom-control-input.is-valid ~ .custom-control-track,
.was-validated .custom-control-input:valid ~ .custom-control-track {
  background-color: rgba(76, 175, 80, 0.5);
}

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .custom-file-input.is-valid:hover ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:hover ~ .custom-file-label {
  border-bottom-color: #4caf50;
  box-shadow: inset 0 -2px 0 -1px #4caf50;
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-bottom-color: #4caf50;
}

.custom-file-input.is-valid ~ .custom-file-label:hover,
.was-validated .custom-file-input:valid ~ .custom-file-label:hover {
  border-bottom-color: #4caf50;
  box-shadow: inset 0 -2px 0 -1px #4caf50;
}

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-switch .custom-control-input.is-valid ~ .custom-control-label::after,
.was-validated .custom-switch .custom-control-input:valid ~ .custom-control-label::after {
  background-color: #4caf50;
}

.form-check-input.is-valid + .form-check-label,
.was-validated .form-check-input:valid + .form-check-label {
  color: #4caf50;
}

.is-valid.form-control, .is-valid.custom-select, .is-valid.form-control-file,
.was-validated .form-control:valid,
.was-validated .custom-select:valid,
.was-validated .form-control-file:valid {
  border-color: #4caf50;
}

.is-valid.form-control:focus, .is-valid.custom-select:focus, .is-valid.form-control-file:focus, .is-valid.form-control:hover, .is-valid.custom-select:hover, .is-valid.form-control-file:hover,
.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control-file:valid:focus,
.was-validated .form-control:valid:hover,
.was-validated .custom-select:valid:hover,
.was-validated .form-control-file:valid:hover {
  border-color: #4caf50;
  box-shadow: inset 0 -2px 0 -1px #4caf50;
}

.is-valid.form-control ~ .valid-feedback, .is-valid.custom-select ~ .valid-feedback, .is-valid.form-control-file ~ .valid-feedback,
.is-valid.form-control ~ .valid-tooltip,
.is-valid.custom-select ~ .valid-tooltip,
.is-valid.form-control-file ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}

select.is-valid.form-control:focus[multiple], select.is-valid.form-control:focus[size]:not([size='1']), textarea.is-valid.form-control:focus:not([rows='1']), .is-valid.custom-select:focus[multiple], .is-valid.custom-select:focus[size]:not([size='1']), select.is-valid.form-control:hover[multiple], select.is-valid.form-control:hover[size]:not([size='1']), textarea.is-valid.form-control:hover:not([rows='1']), .is-valid.custom-select:hover[multiple], .is-valid.custom-select:hover[size]:not([size='1']),
.was-validated select.form-control:valid:focus[multiple],
.was-validated select.form-control:valid:focus[size]:not([size='1']),
.was-validated textarea.form-control:valid:focus:not([rows='1']),
.was-validated .custom-select:valid:focus[multiple],
.was-validated .custom-select:valid:focus[size]:not([size='1']),
.was-validated select.form-control:valid:hover[multiple],
.was-validated select.form-control:valid:hover[size]:not([size='1']),
.was-validated textarea.form-control:valid:hover:not([rows='1']),
.was-validated .custom-select:valid:hover[multiple],
.was-validated .custom-select:valid:hover[size]:not([size='1']) {
  box-shadow: inset 2px 2px 0 -1px #4caf50, inset -2px -2px 0 -1px #4caf50;
}

.textfield-box select.is-valid.form-control:focus[multiple], .textfield-box select.is-valid.form-control:focus[size]:not([size='1']), .textfield-box textarea.is-valid.form-control:focus:not([rows='1']), .textfield-box .is-valid.custom-select:focus[multiple], .textfield-box .is-valid.custom-select:focus[size]:not([size='1']), .textfield-box select.is-valid.form-control:hover[multiple], .textfield-box select.is-valid.form-control:hover[size]:not([size='1']), .textfield-box textarea.is-valid.form-control:hover:not([rows='1']), .textfield-box .is-valid.custom-select:hover[multiple], .textfield-box .is-valid.custom-select:hover[size]:not([size='1']),
.was-validated .textfield-box select.form-control:valid:focus[multiple],
.was-validated .textfield-box select.form-control:valid:focus[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:valid:focus:not([rows='1']),
.was-validated .textfield-box .custom-select:valid:focus[multiple],
.was-validated .textfield-box .custom-select:valid:focus[size]:not([size='1']),
.was-validated .textfield-box select.form-control:valid:hover[multiple],
.was-validated .textfield-box select.form-control:valid:hover[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:valid:hover:not([rows='1']),
.was-validated .textfield-box .custom-select:valid:hover[multiple],
.was-validated .textfield-box .custom-select:valid:hover[size]:not([size='1']) {
  box-shadow: inset 0 -2px 0 -1px #4caf50;
}

.textfield-box .form-control, .textfield-box .custom-select, .textfield-box .form-control-file {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 1rem 1rem calc(1rem - 1px);
}

.textfield-box select.form-control, .textfield-box .custom-select {
  padding-right: 1.5em;
}

.textfield-box select.form-control[multiple], .textfield-box select.form-control[size]:not([size='1']), .textfield-box textarea.form-control:not([rows='1']), .textfield-box .custom-select[multiple], .textfield-box .custom-select[size]:not([size='1']) {
  border-width: 0 0 1px;
  padding: 1rem 1rem calc(1rem - 1px);
}

.textfield-box select.form-control:hover[multiple], .textfield-box select.form-control:hover[size]:not([size='1']), .textfield-box textarea.form-control:hover:not([rows='1']), .textfield-box .custom-select:hover[multiple], .textfield-box .custom-select:hover[size]:not([size='1']) {
  box-shadow: inset 0 -2px 0 -1px rgba(0, 0, 0, 0.87);
}

.textfield-box select.form-control:focus[multiple], .textfield-box select.form-control:focus[size]:not([size='1']), .textfield-box textarea.form-control:focus:not([rows='1']), .textfield-box .custom-select:focus[multiple], .textfield-box .custom-select:focus[size]:not([size='1']) {
  box-shadow: inset 0 -2px 0 -1px #2196f3;
}

.textfield-box textarea.form-control {
  min-height: 3.5rem;
}

.textfield-box .form-control[type='file'],
.textfield-box .form-control-file {
  max-height: 3.5rem;
}

.textfield-box-lg .form-control, .input-group-lg > .textfield-box .form-control, .textfield-box-lg .custom-select, .input-group-lg > .textfield-box .custom-select, .textfield-box-lg .form-control-file, .input-group-lg > .textfield-box .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.textfield-box-lg select.form-control[multiple], .input-group-lg > .textfield-box select.form-control[multiple], .textfield-box-lg select.form-control[size]:not([size='1']), .input-group-lg > .textfield-box select.form-control[size]:not([size='1']), .textfield-box-lg textarea.form-control:not([rows='1']), .input-group-lg > .textfield-box textarea.form-control:not([rows='1']), .textfield-box-lg .custom-select[multiple], .input-group-lg > .textfield-box .custom-select[multiple], .textfield-box-lg .custom-select[size]:not([size='1']), .input-group-lg > .textfield-box .custom-select[size]:not([size='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.textfield-box-lg textarea.form-control, .input-group-lg > .textfield-box textarea.form-control {
  min-height: 4.25rem;
}

.textfield-box-lg .custom-select, .input-group-lg > .textfield-box .custom-select {
  padding-right: 1.5em;
}

.textfield-box-lg .form-control[type='file'], .input-group-lg > .textfield-box .form-control[type='file'],
.textfield-box-lg .form-control-file,
.input-group-lg > .textfield-box .form-control-file {
  max-height: 4.25rem;
}

.textfield-box-sm .form-control, .input-group-sm > .textfield-box .form-control, .textfield-box-sm .custom-select, .input-group-sm > .textfield-box .custom-select, .textfield-box-sm .form-control-file, .input-group-sm > .textfield-box .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.textfield-box-sm select.form-control[multiple], .input-group-sm > .textfield-box select.form-control[multiple], .textfield-box-sm select.form-control[size]:not([size='1']), .input-group-sm > .textfield-box select.form-control[size]:not([size='1']), .textfield-box-sm textarea.form-control:not([rows='1']), .input-group-sm > .textfield-box textarea.form-control:not([rows='1']), .textfield-box-sm .custom-select[multiple], .input-group-sm > .textfield-box .custom-select[multiple], .textfield-box-sm .custom-select[size]:not([size='1']), .input-group-sm > .textfield-box .custom-select[size]:not([size='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.textfield-box-sm textarea.form-control, .input-group-sm > .textfield-box textarea.form-control {
  min-height: 2.75rem;
}

.textfield-box-sm .custom-select, .input-group-sm > .textfield-box .custom-select {
  padding-right: 1.5em;
}

.textfield-box-sm .form-control[type='file'], .input-group-sm > .textfield-box .form-control[type='file'],
.textfield-box-sm .form-control-file,
.input-group-sm > .textfield-box .form-control-file {
  max-height: 2.75rem;
}

.floating-label {
  padding-top: 0.75rem;
  position: relative;
}

.floating-label.has-value label,
.floating-label.is-focused label {
  top: 0;
  transform: scale(0.75);
}

.floating-label.is-focused label {
  color: #2196f3;
}

.floating-label:not(.has-value):not(.is-focused) .form-control[type='date'], .floating-label:not(.has-value):not(.is-focused) .form-control[type='datetime-local'], .floating-label:not(.has-value):not(.is-focused) .form-control[type='time'] {
  color: transparent;
}

.floating-label label {
  transition-duration: 0.3s;
  transition-property: color, top, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.38);
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.125rem;
  left: 0;
  transform-origin: 0 0;
}

@media (min-width: 576px) {
  .floating-label label {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .floating-label label {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .floating-label label {
    transition: none;
  }
}

.floating-label .form-control, .floating-label .custom-select, .floating-label .form-control-file {
  position: relative;
}

.floating-label .form-control:focus::placeholder, .floating-label .custom-select:focus::placeholder, .floating-label .form-control-file:focus::placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: transparent;
  opacity: 0;
}

@media (min-width: 576px) {
  .floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
    transition: none;
  }
}

.floating-label-lg.has-value label, .input-group-lg > .has-value.floating-label label,
.floating-label-lg.is-focused label,
.input-group-lg > .is-focused.floating-label label {
  transform: scale(0.411765);
}

.floating-label-lg label, .input-group-lg > .floating-label label {
  font-size: 2.125rem;
  line-height: 1.176471;
  top: 1.5rem;
}

.floating-label-lg .form-control, .input-group-lg > .floating-label .form-control, .floating-label-lg .custom-select, .input-group-lg > .floating-label .custom-select, .floating-label-lg .form-control-file, .input-group-lg > .floating-label .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.floating-label-lg select.form-control, .input-group-lg > .floating-label select.form-control, .floating-label-lg .custom-select, .input-group-lg > .floating-label .custom-select {
  padding-right: 1.5em;
}

.floating-label-lg select.form-control[multiple], .input-group-lg > .floating-label select.form-control[multiple], .floating-label-lg select.form-control[size]:not([size='1']), .input-group-lg > .floating-label select.form-control[size]:not([size='1']), .floating-label-lg textarea.form-control:not([rows='1']), .input-group-lg > .floating-label textarea.form-control:not([rows='1']), .floating-label-lg .custom-select[multiple], .input-group-lg > .floating-label .custom-select[multiple], .floating-label-lg .custom-select[size]:not([size='1']), .input-group-lg > .floating-label .custom-select[size]:not([size='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.floating-label-sm.has-value label, .input-group-sm > .has-value.floating-label label,
.floating-label-sm.is-focused label,
.input-group-sm > .is-focused.floating-label label {
  transform: scale(0.769231);
}

.floating-label-sm label, .input-group-sm > .floating-label label {
  font-size: 0.8125rem;
  line-height: 1.538462;
  top: 1.0rem;
}

.floating-label-sm .form-control, .input-group-sm > .floating-label .form-control, .floating-label-sm .custom-select, .input-group-sm > .floating-label .custom-select, .floating-label-sm .form-control-file, .input-group-sm > .floating-label .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.floating-label-sm select.form-control, .input-group-sm > .floating-label select.form-control, .floating-label-sm .custom-select, .input-group-sm > .floating-label .custom-select {
  padding-right: 1.5em;
}

.floating-label-sm select.form-control[multiple], .input-group-sm > .floating-label select.form-control[multiple], .floating-label-sm select.form-control[size]:not([size='1']), .input-group-sm > .floating-label select.form-control[size]:not([size='1']), .floating-label-sm textarea.form-control:not([rows='1']), .input-group-sm > .floating-label textarea.form-control:not([rows='1']), .floating-label-sm .custom-select[multiple], .input-group-sm > .floating-label .custom-select[multiple], .floating-label-sm .custom-select[size]:not([size='1']), .input-group-sm > .floating-label .custom-select[size]:not([size='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.floating-label.textfield-box {
  padding-top: 0;
}

.floating-label.textfield-box.has-value label,
.floating-label.textfield-box.is-focused label {
  line-height: 1;
  top: 0.5rem;
}

.floating-label.textfield-box label {
  top: 1rem;
  left: 1rem;
}

.floating-label.textfield-box .form-control, .floating-label.textfield-box .custom-select, .floating-label.textfield-box .form-control-file,
.floating-label.textfield-box select.form-control[multiple],
.floating-label.textfield-box select.form-control[size]:not([size='1']),
.floating-label.textfield-box textarea.form-control:not([rows='1']),
.floating-label.textfield-box .custom-select[multiple],
.floating-label.textfield-box .custom-select[size]:not([size='1']) {
  padding-top: 1.5rem;
}

.floating-label.textfield-box .form-control, .floating-label.textfield-box .custom-select, .floating-label.textfield-box .form-control-file {
  padding-bottom: calc(0.5rem - 1px);
}

.floating-label-lg.textfield-box label, .input-group-lg > .textfield-box.floating-label label {
  top: 0.875rem;
  left: 1rem;
}

.floating-label-lg.textfield-box .form-control, .input-group-lg > .textfield-box.floating-label .form-control, .floating-label-lg.textfield-box .custom-select, .input-group-lg > .textfield-box.floating-label .custom-select, .floating-label-lg.textfield-box .form-control-file, .input-group-lg > .textfield-box.floating-label .form-control-file,
.floating-label-lg.textfield-box select.form-control[multiple],
.input-group-lg > .textfield-box.floating-label select.form-control[multiple],
.floating-label-lg.textfield-box select.form-control[size]:not([size='1']),
.input-group-lg > .textfield-box.floating-label select.form-control[size]:not([size='1']),
.floating-label-lg.textfield-box textarea.form-control:not([rows='1']),
.input-group-lg > .textfield-box.floating-label textarea.form-control:not([rows='1']),
.floating-label-lg.textfield-box .custom-select[multiple],
.input-group-lg > .textfield-box.floating-label .custom-select[multiple],
.floating-label-lg.textfield-box .custom-select[size]:not([size='1']),
.input-group-lg > .textfield-box.floating-label .custom-select[size]:not([size='1']) {
  padding-top: 1.249999rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.floating-label-lg.textfield-box select.form-control, .input-group-lg > .textfield-box.floating-label select.form-control, .floating-label-lg.textfield-box .custom-select, .input-group-lg > .textfield-box.floating-label .custom-select {
  padding-right: 1.5em;
}

.floating-label-sm.textfield-box label, .input-group-sm > .textfield-box.floating-label label {
  top: 0.75rem;
  left: 0.75rem;
}

.floating-label-sm.textfield-box .form-control, .input-group-sm > .textfield-box.floating-label .form-control, .floating-label-sm.textfield-box .custom-select, .input-group-sm > .textfield-box.floating-label .custom-select, .floating-label-sm.textfield-box .form-control-file, .input-group-sm > .textfield-box.floating-label .form-control-file,
.floating-label-sm.textfield-box select.form-control[multiple],
.input-group-sm > .textfield-box.floating-label select.form-control[multiple],
.floating-label-sm.textfield-box select.form-control[size]:not([size='1']),
.input-group-sm > .textfield-box.floating-label select.form-control[size]:not([size='1']),
.floating-label-sm.textfield-box textarea.form-control:not([rows='1']),
.input-group-sm > .textfield-box.floating-label textarea.form-control:not([rows='1']),
.floating-label-sm.textfield-box .custom-select[multiple],
.input-group-sm > .textfield-box.floating-label .custom-select[multiple],
.floating-label-sm.textfield-box .custom-select[size]:not([size='1']),
.input-group-sm > .textfield-box.floating-label .custom-select[size]:not([size='1']) {
  padding-top: 1.0rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.floating-label-sm.textfield-box select.form-control, .input-group-sm > .textfield-box.floating-label select.form-control, .floating-label-sm.textfield-box .custom-select, .input-group-sm > .textfield-box.floating-label .custom-select {
  padding-right: 1.5em;
}

.input-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .floating-label,
.input-group > .form-control,
.input-group > .form-control-file,
.input-group > .textfield-box {
  flex: 1 1 auto;
  margin-bottom: 0;
  width: 1%;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .floating-label,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .form-control-file,
.input-group > .custom-file + .textfield-box,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .floating-label,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .form-control-file,
.input-group > .custom-select + .textfield-box,
.input-group > .floating-label + .custom-file,
.input-group > .floating-label + .custom-select,
.input-group > .floating-label + .floating-label,
.input-group > .floating-label + .form-control,
.input-group > .floating-label + .form-control-file,
.input-group > .floating-label + .textfield-box,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .floating-label,
.input-group > .form-control + .form-control,
.input-group > .form-control + .form-control-file,
.input-group > .form-control + .textfield-box,
.input-group > .form-control-file + .custom-file,
.input-group > .form-control-file + .custom-select,
.input-group > .form-control-file + .floating-label,
.input-group > .form-control-file + .form-control,
.input-group > .form-control-file + .form-control-file,
.input-group > .form-control-file + .textfield-box,
.input-group > .textfield-box + .custom-file,
.input-group > .textfield-box + .custom-select,
.input-group > .textfield-box + .floating-label,
.input-group > .textfield-box + .form-control,
.input-group > .textfield-box + .form-control-file,
.input-group > .textfield-box + .textfield-box {
  margin-left: 1rem;
}

.input-group-append,
.input-group-prepend {
  align-items: center;
  display: flex;
  justify-content: center;
}

.input-group-append .btn,
.input-group-prepend .btn {
  min-width: 0;
}

.input-group-append .btn + .btn,
.input-group-prepend .btn + .btn {
  margin-left: -1px;
}

.input-group-append {
  margin-left: 1rem;
}

.input-group-prepend {
  margin-right: 1rem;
}

.input-group-text {
  color: inherit;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  white-space: nowrap;
}

.input-group-text + .input-group-text {
  margin-left: 0.5rem;
}

.input-group > .input-group-append > .btn:not(:first-of-type),
.input-group > .input-group-prepend > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-append > .btn:not(:last-of-type),
.input-group > .input-group-prepend > .btn:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-icon {
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.42);
  font-size: 0.875rem;
  margin-right: 1rem;
  order: -1;
}

@media (min-width: 576px) {
  .input-group-icon {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .input-group-icon {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .input-group-icon {
    transition: none;
  }
}

.input-group-icon:hover {
  color: rgba(0, 0, 0, 0.87);
}

.floating-label.is-focused ~ .input-group-icon,
.form-control:focus ~ .input-group-icon {
  color: #2196f3;
}

.floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.75rem;
}

.input-group-lg > .floating-label.has-value label,
.input-group-lg > .floating-label.is-focused label {
  top: 0;
}

.input-group-lg > .floating-label.textfield-box.has-value label,
.input-group-lg > .floating-label.textfield-box.is-focused label {
  top: 0.5rem;
}

.input-group-lg > .floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.875rem;
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.input-group-lg > select.form-control, .input-group-lg > .custom-select {
  padding-right: 1.5em;
}

.input-group-lg > select.form-control[multiple], .input-group-lg > select.form-control[size]:not([size='1']), .input-group-lg > textarea.form-control:not([rows='1']), .input-group-lg > .custom-select[multiple], .input-group-lg > .custom-select[size]:not([size='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.input-group-lg > .form-control[type='file'],
.input-group-lg > .form-control-file {
  max-height: 4.25rem;
}

.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .input-group-text {
  font-size: 2.125rem;
  line-height: 1.176471;
}

.input-group-lg > .input-group-icon {
  font-size: 1.859375rem;
  line-height: 1.176471;
}

.input-group-sm > .floating-label.has-value label,
.input-group-sm > .floating-label.is-focused label {
  top: 0;
}

.input-group-sm > .floating-label.textfield-box.has-value label,
.input-group-sm > .floating-label.textfield-box.is-focused label {
  top: 0.5rem;
}

.input-group-sm > .floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.625rem;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.input-group-sm > select.form-control, .input-group-sm > .custom-select {
  padding-right: 1.5em;
}

.input-group-sm > select.form-control[multiple], .input-group-sm > select.form-control[size]:not([size='1']), .input-group-sm > textarea.form-control:not([rows='1']), .input-group-sm > .custom-select[multiple], .input-group-sm > .custom-select[size]:not([size='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.input-group-sm > .form-control[type='file'],
.input-group-sm > .form-control-file {
  max-height: 2.75rem;
}

.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .input-group-text {
  font-size: 0.8125rem;
  line-height: 1.538462;
}

.input-group-sm > .input-group-icon {
  font-size: 0.710938rem;
  line-height: 1.538462;
}

.navbar {
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 3.5rem;
  padding: 0.625rem 1rem;
  position: relative;
}

.navbar .form-control {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  border: 0;
  color: inherit;
  opacity: 0.7;
  padding: 0.375rem 1rem;
}

@media (min-width: 576px) {
  .navbar .form-control {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navbar .form-control {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar .form-control {
    transition: none;
  }
}

.navbar .form-control:focus, .navbar .form-control:hover {
  box-shadow: none;
  opacity: 1;
}

.navbar .form-control-lg {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar .form-control-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.navbar .input-group {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 0.7;
}

@media (min-width: 576px) {
  .navbar .input-group {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navbar .input-group {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar .input-group {
    transition: none;
  }
}

.navbar .input-group:focus, .navbar .input-group:hover {
  opacity: 1;
}

.navbar .input-group .form-control {
  background-color: transparent;
  opacity: 1;
}

.navbar .input-group .form-control:not(:first-child) {
  padding-left: 0;
}

.navbar .input-group .form-control:not(:last-child) {
  padding-right: 0;
}

.navbar .input-group .input-group-append {
  margin-left: 0;
  min-width: 0;
}

.navbar .input-group .input-group-prepend {
  margin-right: 0;
  min-width: 0;
}

.navbar .input-group .input-group-text {
  margin-right: 1rem;
  margin-left: 1rem;
}

.navbar .input-group-lg > .form-control {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar .input-group-sm > .form-control {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.navbar .nav-tabs {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.navbar .nav-tabs .nav-link {
  min-height: 3.5rem;
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
}

.navbar > .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar-dark {
  color: white;
}

.navbar-dark .form-control,
.navbar-dark .input-group,
.navbar-dark .navbar-brand::before,
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-toggler::before {
  background-color: rgba(255, 255, 255, 0.12);
}

.navbar-dark .form-control::placeholder,
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-fixed-top {
  top: 0;
}

.navbar-full {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 40;
}

@supports (position: sticky) {
  .navbar-sticky-top {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 40;
  }
}

.toolbar-waterfall {
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 40;
}

@media (min-width: 576px) {
  .toolbar-waterfall {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .toolbar-waterfall {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .toolbar-waterfall {
    transition: none;
  }
}

.toolbar-waterfall.waterfall {
  background-color: #ffe600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
}

.navbar-brand {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 2.25rem;
  position: relative;
  white-space: nowrap;
}

.navbar-brand:active, .navbar-brand:focus, .navbar-brand:hover {
  color: inherit;
  text-decoration: none;
}

.navbar-brand::before {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  content: '';
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  right: -0.5rem;
  bottom: 0;
  left: -0.5rem;
}

@media (min-width: 576px) {
  .navbar-brand::before {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navbar-brand::before {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar-brand::before {
    transition: none;
  }
}

.navbar-brand:focus {
  outline: 0;
}

.navbar-brand:focus::before {
  opacity: 1;
}

.navbar-brand:not(:first-child) {
  margin-left: 1rem;
}

.navbar-brand:not(:last-child) {
  margin-right: 1rem;
}

.navbar-text {
  align-items: center;
  color: inherit;
  display: inline-flex;
  flex-wrap: wrap;
  height: 2.25rem;
}

.navbar-nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-nav .nav-link {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: background-color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: inherit;
  font-size: 1rem;
  height: 2.25rem;
  line-height: 1;
  opacity: 0.7;
  padding: 0.625rem 1rem;
}

@media (min-width: 576px) {
  .navbar-nav .nav-link {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navbar-nav .nav-link {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-nav .nav-link:active, .navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.disabled {
  opacity: 1;
}

.navbar-nav .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
}

.navbar-nav .nav-link:focus {
  outline: 0;
}

.navbar-nav .active > .nav-link,
.navbar-nav .show > .nav-link {
  opacity: 1;
}

.navbar-nav .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .navbar-toggler:first-child + .navbar-brand {
    margin-left: 0;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .navbar-toggler:first-child + .navbar-brand {
    margin-left: 0;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-toggler:first-child + .navbar-brand {
    margin-left: 0;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .navbar-toggler:first-child + .navbar-brand {
    margin-left: 0;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .navbar-toggler:first-child + .navbar-brand {
  margin-left: 0;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-toggler {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  color: inherit;
  display: inline-flex;
  flex-shrink: 0;
  height: 2.25rem;
  justify-content: center;
  line-height: 1;
  padding: 0;
  position: relative;
  width: 2.25rem;
}

.navbar-toggler:active, .navbar-toggler:focus, .navbar-toggler:hover {
  color: inherit;
  text-decoration: none;
}

.navbar-toggler:active::before, .navbar-toggler:focus::before, .navbar-toggler:hover::before {
  opacity: 1;
}

.navbar-toggler::before {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  content: '';
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .navbar-toggler::before {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .navbar-toggler::before {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .navbar-toggler::before {
    transition: none;
  }
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  display: block;
}

.navbar-toggler-icon::before {
  content: "menu";
}

.tooltip {
  text-align: left;
  text-align: start;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.428572;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  display: block;
  font-size: 0.875rem;
  line-height: 1.428572;
  margin: 1.5rem;
  opacity: 0;
  position: absolute;
  word-break: break-word;
  z-index: 240;
}

[dir='rtl'] .tooltip {
  text-align: right;
  text-align: start;
}

@media (min-width: 768px) {
  .tooltip {
    font-size: 0.625rem;
    margin: 0.875rem;
  }
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip.show .tooltip-inner {
  transform: scale(1);
}

.tooltip-inner {
  border-radius: 4px;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #616161;
  color: white;
  padding: 0.375rem 1rem;
  text-align: center;
  transform: scale(0.87);
}

@media (min-width: 576px) {
  .tooltip-inner {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .tooltip-inner {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .tooltip-inner {
    transition: none;
  }
}

@media (min-width: 768px) {
  .tooltip-inner {
    padding: 0.241071rem 0.5rem;
  }
}

.align-top {
  vertical-align: top !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-black-primary {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.border-black-secondary {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.border-black-hint {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.border-black-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-white-primary {
  border-color: white !important;
}

.border-white-secondary {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-white-hint {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border-white-divider {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.border-primary {
  border-color: #ffe600 !important;
}

.border-secondary {
  border-color: #2196f3 !important;
}

.border-danger {
  border-color: #f44336 !important;
}

.border-info {
  border-color: #2196f3 !important;
}

.border-success {
  border-color: #4caf50 !important;
}

.border-warning {
  border-color: #ff9800 !important;
}

.border-dark {
  border-color: #424242 !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.rounded {
  border-radius: 4px;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bg-dark-1 {
  background-color: #000000 !important;
}

.bg-dark-2 {
  background-color: #212121 !important;
}

.bg-dark-3 {
  background-color: #303030 !important;
}

.bg-dark-4 {
  background-color: #424242 !important;
}

.bg-light-1 {
  background-color: #e0e0e0 !important;
}

.bg-light-2 {
  background-color: #f5f5f5 !important;
}

.bg-light-3 {
  background-color: #fafafa !important;
}

.bg-light-4 {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #ffffff !important;
}

a.bg-primary:active, a.bg-primary:focus, a.bg-primary:hover {
  background-color: #c7b400 !important;
}

.bg-primary {
  background-color: #ffe600 !important;
}

a.bg-secondary:active, a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #0069c0 !important;
}

.bg-secondary {
  background-color: #2196f3 !important;
}

a.bg-danger:active, a.bg-danger:focus, a.bg-danger:hover {
  background-color: #d32f2f !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

a.bg-info:active, a.bg-info:focus, a.bg-info:hover {
  background-color: #1976d2 !important;
}

.bg-info {
  background-color: #2196f3 !important;
}

a.bg-success:active, a.bg-success:focus, a.bg-success:hover {
  background-color: #388e3c !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

a.bg-warning:active, a.bg-warning:focus, a.bg-warning:hover {
  background-color: #f57c00 !important;
}

.bg-warning {
  background-color: #ff9800 !important;
}

a.bg-dark:active, a.bg-dark:focus, a.bg-dark:hover {
  background-color: #212121 !important;
}

.bg-dark {
  background-color: #424242 !important;
}

a.bg-light:active, a.bg-light:focus, a.bg-light:hover {
  background-color: #e0e0e0 !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

.bg-primary-dark {
  background-color: #c7b400 !important;
}

.bg-primary-light {
  background-color: #ffff55 !important;
}

.bg-secondary-dark {
  background-color: #0069c0 !important;
}

.bg-secondary-light {
  background-color: #6ec6ff !important;
}

.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-table-row {
  display: table-row !important;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
}

@media print {
  .d-print-block {
    display: block !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.order-first {
  order: -1;
}

.order-last {
  order: 1;
}

.order-0 {
  order: 0;
}

@media (min-width: 576px) {
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 1;
  }
  .order-sm-0 {
    order: 0;
  }
}

@media (min-width: 768px) {
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 1;
  }
  .order-md-0 {
    order: 0;
  }
}

@media (min-width: 992px) {
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 1;
  }
  .order-lg-0 {
    order: 0;
  }
}

@media (min-width: 1200px) {
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 1;
  }
  .order-xl-0 {
    order: 0;
  }
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.float-right {
  float: right !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .float-sm-right {
    float: right !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-none {
    float: none !important;
  }
  .float-md-right {
    float: right !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .float-lg-right {
    float: right !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .float-xl-right {
    float: right !important;
  }
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-relative {
  position: relative !important;
}

.position-static {
  position: static !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 40;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 40;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.shadow {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-sm {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-24 {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-16 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-12 {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-8 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 15px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-4 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-2 {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-1 {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .text-md-center {
    text-align: center !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
}

.text-black {
  color: #000000 !important;
}

.text-black-primary {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-black-secondary {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-black-hint {
  color: rgba(0, 0, 0, 0.38) !important;
}

.text-black-divider {
  color: rgba(0, 0, 0, 0.12) !important;
}

.text-white {
  color: #ffffff !important;
}

.text-white-primary {
  color: white !important;
}

.text-white-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-white-hint {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-divider {
  color: rgba(255, 255, 255, 0.12) !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.38) !important;
}

a.text-primary:active, a.text-primary:focus, a.text-primary:hover {
  color: #c7b400 !important;
}

.text-primary {
  color: #ffe600 !important;
}

a.text-secondary:active, a.text-secondary:focus, a.text-secondary:hover {
  color: #0069c0 !important;
}

.text-secondary {
  color: #2196f3 !important;
}

a.text-danger:active, a.text-danger:focus, a.text-danger:hover {
  color: #d32f2f !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-info:active, a.text-info:focus, a.text-info:hover {
  color: #1976d2 !important;
}

.text-info {
  color: #2196f3 !important;
}

a.text-success:active, a.text-success:focus, a.text-success:hover {
  color: #388e3c !important;
}

.text-success {
  color: #4caf50 !important;
}

a.text-warning:active, a.text-warning:focus, a.text-warning:hover {
  color: #f57c00 !important;
}

.text-warning {
  color: #ff9800 !important;
}

a.text-dark:active, a.text-dark:focus, a.text-dark:hover {
  color: #212121 !important;
}

.text-dark {
  color: #424242 !important;
}

a.text-light:active, a.text-light:focus, a.text-light:hover {
  color: #e0e0e0 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-body {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-monospace {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.font-italic {
  font-style: italic;
}

.font-weight-bold,
.font-weight-medium {
  font-weight: 500;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal,
.font-weight-regular {
  font-weight: 400;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.material-icons {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
}

.material-icons-inline {
  font-size: inherit;
  line-height: 1;
}

@media print {
  @page {
    size: a3;
  }
  *,
  *::after,
  *::before {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (" attr(title) ") ';
  }
  blockquote {
    page-break-inside: avoid;
  }
  body {
    min-width: 992px !important;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
  }
  pre {
    page-break-inside: avoid;
    white-space: pre-wrap !important;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  .card {
    border: 1px solid #e1e1e1;
  }
  .container {
    min-width: 992px !important;
  }
  .dropdown-menu {
    border: 1px solid #e1e1e1;
  }
  .list-group-item {
    border: 1px solid #e1e1e1;
    margin-bottom: -1px;
  }
  .list-group-item:last-child {
    margin-bottom: 0;
  }
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }
  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
  }
  .nav-tabs {
    border-bottom: 1px solid #e1e1e1;
  }
  .navbar {
    display: none;
  }
  .popover {
    border: 1px solid #e1e1e1;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered {
    border: 1px solid #e1e1e1;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark td,
  .table-dark th,
  .table-dark .table {
    border-color: #e1e1e1;
  }
  .table .thead-dark td,
  .table .thead-dark th {
    color: inherit;
  }
}

.unsw-avatar {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  width: 2.5rem;
}

.navbar .unsw-avatar {
  height: 2.25rem;
  width: 2.25rem;
}

.btn-float.unsw-btn-float {
  height: 2.25rem;
  line-height: 2.25rem;
  width: 2.25rem;
}

@media (min-width: 768px) {
  .unsw-card-grid {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .unsw-card-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.unsw-card-grid .card {
  margin-bottom: 0.5rem;
}

.unsw-collapse-peak {
  display: block;
  height: 3.750002rem;
  min-height: 3.750002rem;
  overflow: hidden;
}

.unsw-collapse-peak.show {
  height: auto;
  overflow: visible;
}

.unsw-collapse-peak > :last-child {
  margin-bottom: 0;
}

.unsw-dropdown-content {
  max-height: calc(100vh - 7rem);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  min-height: 100vh;
}

.unsw-navbar-header {
  z-index: 39;
}

.unsw-mw-0 {
  min-width: 0 !important;
}

.unsw-scroll-h {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
}

.unsw-scroll-v {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
}

.gui-player-container {
  padding-top: 1rem;
}

@media (min-width: 992px) {
  .gui-player-container {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
}

@media (max-width: 991.98px) {
  .gui-player-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 20px;
  }
  .gui-player-nav .carousel-control-next,
  .gui-player-nav .carousel-control-prev {
    margin-top: 0;
    margin-left: 1rem;
    position: static;
  }
  .gui-player-nav .carousel-control-next:first-child,
  .gui-player-nav .carousel-control-prev:first-child {
    margin-left: 0;
  }
}

.gui-player-nav .carousel-control-next,
.gui-player-nav .carousel-control-prev {
  opacity: 1;
}

.gui-timeline {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 2.25rem;
  position: relative;
}

.gui-timeline-comment {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  height: 2.25rem;
  line-height: 1.125rem;
  padding-top: 1.125rem;
  position: relative;
}

.gui-timeline-comment-body a {
  color: inherit;
}

.gui-timeline-comment-user > .active {
  position: absolute;
  top: 0;
  transform: translateZ(0);
}

.gui-timeline-comment-user > .active::after {
  background-color: currentColor;
  content: '';
  height: 0.5625rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
}

.gui-timeline-comment-user > .active::before {
  background-color: #f44336;
  content: '';
  height: 0.5625rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 1px;
}

.gui-timeline-comment-user > .active > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 80%;
  padding-left: 0.8125rem;
  position: relative;
}

.gui-timeline-comment-user > .active > span::before {
  background-color: currentColor;
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.5625rem;
}

.gui-timeline-comment-user > :not(.active) {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.gui-timeline-container {
  color: white;
}

@media (min-width: 992px) {
  .gui-timeline-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.gui-timeline-marker {
  background-color: rgba(255, 255, 255, 0.12);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
  z-index: 1;
}

.gui-timeline-toggle {
  border: 0;
  margin-right: auto;
}

@media (min-width: 992px) {
  .gui-timeline-toggle {
    position: absolute;
    top: auto;
    bottom: 5.5rem;
    left: 1.25rem;
    z-index: 1;
  }
}

.html-chart-bar {
  transition-duration: 0.3s;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: flex-end;
  display: flex;
  flex: 1 0 auto;
  padding-right: 1px;
}

@media (min-width: 576px) {
  .html-chart-bar {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .html-chart-bar {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .html-chart-bar {
    transition: none;
  }
}

.html-chart-bar-negative, .html-chart-bar-positive {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition-duration: 0.3s;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: inherit;
  cursor: pointer;
  display: block;
  text-decoration: none;
  transform: translateZ(0);
  width: 100%;
}

@media (min-width: 576px) {
  .html-chart-bar-negative, .html-chart-bar-positive {
    transition-duration: 0.39s;
  }
}

@media (min-width: 992px) {
  .html-chart-bar-negative, .html-chart-bar-positive {
    transition-duration: 0.2s;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .html-chart-bar-negative, .html-chart-bar-positive {
    transition: none;
  }
}

.html-chart-bar-positive {
  background-color: rgba(255, 230, 0, 0.7);
}

.html-chart-bar-positive:focus, .html-chart-bar-positive:hover {
  background-color: #ffe600;
}

.html-chart-main {
  display: flex;
  height: 100%;
  margin-right: -1px;
  margin-left: -1px;
  position: relative;
}

.html-chart-wrapper {
  height: 100%;
  width: 100%;
}

/*# sourceMappingURL=base.css.map */