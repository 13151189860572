body {
  min-height: 100vh;
}

.unsw-navbar-header {
  z-index: map-get($toolbar-elevation-shadow, elevation) - 1;
}

.unsw-mw-0 {
  min-width: 0 !important; // stylelint-disable-line declaration-no-important
}

.unsw-scroll-h {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
}

.unsw-scroll-v {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
}
