.unsw-card-grid {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: $card-margin-x;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .card {
    margin-bottom: $card-margin-y;
  }
}
