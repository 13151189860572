// stylelint-disable scss/dollar-variable-default

$primary: (
  color: #ffe600,
  dark:  #c7b400,
  light: #ffff55
);

// $secondary: (
//   color: #32c1f1,
//   dark:  #0091be,
//   light: #77f4ff
// );

$secondary: (
  color: #2196f3,
  dark:  #0069c0,
  light: #6ec6ff
);

$border-radius:                   4px;
$navdrawer-nav-link-color-active: map-get($secondary, color);

// Material styles
@import '../../node_modules/daemonite-material/assets/scss/material';

// UNSW theBox styles
@import 'variables';
@import 'avatar';
@import 'button';
@import 'card';
@import 'collapse';
@import 'dropdown';
@import 'layout';
@import 'timeline';
