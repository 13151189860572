.unsw-avatar {
  @include text-truncate();

  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: $unsw-avatar-size;
  justify-content: center;
  line-height: 1;
  width: $unsw-avatar-size;

  .navbar & {
    height: $toolbar-element-height;
    width: $toolbar-element-height;
  }
}
