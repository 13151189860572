.unsw-collapse-peak {
  display: block;
  height: ($font-size-base * $line-height-base * 3);
  min-height: ($font-size-base * $line-height-base * 3);
  overflow: hidden;

  &.show {
    height: auto;
    overflow: visible;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
