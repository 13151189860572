.gui-player-container {
  padding-top: $card-padding-y;

  @include media-breakpoint-up(lg) {
    padding-top: ($btn-height * 2 + $card-inner-spacer-y);
    padding-bottom: ($btn-height * 2 + $card-inner-spacer-y);
  }
}

.gui-player-nav {
  @include media-breakpoint-down(md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ($card-inner-spacer-y / 2) ($grid-gutter-width / 2);

    .carousel-control-next,
    .carousel-control-prev {
      margin-top: 0;
      margin-left: $card-inner-spacer-x;
      position: static;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }
}

.gui-timeline {
  border-right: $border-width solid $white-hint;
  border-left: $border-width solid $white-hint;
  height: $btn-height;
  position: relative;
}

.gui-timeline-comment {
  @include typography-caption();

  height: $btn-height;
  line-height: ($btn-height / 2);
  padding-top: ($btn-height / 2);
  position: relative;
}

.gui-timeline-comment-body {
  a {
    color: inherit;
  }
}

.gui-timeline-comment-user {
  > .active {
    position: absolute;
    top: 0;
    transform: translateZ(0);

    &::after {
      background-color: currentColor;
      content: '';
      height: ($font-size-caption * $line-height-caption / 2);
      position: absolute;
      top: 0;
      left: 0;
      width: $border-width;
    }

    &::before {
      background-color: theme-color(danger);
      content: '';
      height: ($font-size-caption * $line-height-caption / 2);
      position: absolute;
      bottom: 100%;
      left: 0;
      width: $border-width;
    }

    > span {
      @include text-truncate;

      display: block;
      font-size: $small-font-size;
      padding-left: ($font-size-caption * $line-height-caption / 2 + $spacer-xs);
      position: relative;

      &::before {
        background-color: currentColor;
        content: '';
        height: $border-width;
        position: absolute;
        top: 50%;
        left: 0;
        width: ($font-size-caption * $line-height-caption / 2);
      }
    }
  }

  > :not(.active) {
    @include sr-only();
  }
}

.gui-timeline-container {
  color: $white-primary;

  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.gui-timeline-marker {
  background-color: $white-divider;
  border-right: ($border-width * 2) solid $white-secondary;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
  z-index: 1;
}

.gui-timeline-toggle {
  border: 0;
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: auto;
    bottom: ($btn-height * 2 + $card-inner-spacer-y);
    left: ($carousel-control-size / 2);
    z-index: 1;
  }
}

.html-chart-bar {
  @include transition-standard(height);

  align-items: flex-end;
  display: flex;
  flex: 1 0 auto;
  padding-right: $border-width;

  &-negative,
  &-positive {
    @include border-top-radius($border-radius);
    @include transition-standard(height);

    color: inherit;
    cursor: pointer;
    display: block;
    text-decoration: none;
    transform: translateZ(0);
    width: 100%;
  }

  &-positive {
    background-color: rgba(theme-color(primary), $white-secondary-opacity);

    @include focus-hover {
      background-color: theme-color(primary);
    }
  }
}

.html-chart-main {
  display: flex;
  height: 100%;
  margin-right: ($border-width * -1);
  margin-left: ($border-width * -1);
  position: relative;
}

.html-chart-wrapper {
  height: 100%;
  width: 100%;
}
